import { Image } from 'antd';
import React from 'react';
import Helper from '../../class/Helper';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            post_user: {},
            comment: []
        }
    }
    componentDidMount() {
        global.Ajax.hhtc_request('post/postDetail', { id: this.props.data.id }).then(res => {
            if (res.code == 1) {
                this.setState(res.data)
            }
        })
    }
    render() {
        const { post_user, content, imgs, comment, comment_num } = this.state;
        return (
            <div style={{ position: 'relative', paddingTop: 1 }}>
                <div className='wrapbox flexColumn'>
                    <div className='boxx ovauu' style={{ minHeight: '100%' }}>
                        <div className='flexCenter'>
                            <div className='avatar' style={{ width: 44, height: 44, }}>
                                <img alt='' src={post_user.avatar || ''} style={{ width: 44, height: 44, borderRadius: '100%' }} />
                                {post_user.vip_level > 1 && <img alt='' src={require('../../imgs/vip.png').default} className='sex' style={{ background: 'transparent', border: 0 }} />}
                            </div>
                            <div style={{ flex: 1, marginLeft: 10 }}>
                                <div className='flexCenter'>
                                    <p style={{ fontSize: 16, fontWeight: 'bold' }}>{post_user.name || post_user.true_name}</p>
                                    {post_user.company_name!=''&&<div className='flexCenter' style={{ background: 'linear-gradient(to bottom,#FF6900,#FFA800)', height: 16, borderRadius: 8, padding: '0 6px', marginLeft: 4 }}>
                                        <img alt='' src={require('../../imgs/ren.png').default} style={{ display: 'block', width: 9, }} />
                                        <p style={{ fontSize: 12, color: '#fff', lineHeight: '16px', marginLeft: 4 }}>{post_user.job_name || ''}</p>
                                    </div>}
                                </div>
                                <p style={{ fontSize: 12, color: '#A5A7AD' }}>{post_user.company_name || '求职者'}</p>
                                {/* <h1 className='name' style={{ fontSize: 16, }}>林琳琳</h1>
                                <p className='p1' style={{ fontSize: 12 }}>求职者</p> */}
                            </div>
                        </div>
                        <p className='p5' style={{ fontSize: 14, marginTop: 15 }}>{content}</p>
                        <div className='borderbot1'>
                            {imgs && imgs.length > 0 && <div className='flwp'>
                                {imgs.map((item, index) => (
                                    <img alt='' src={item || ''} className='item32' key={String(index)} style={{ marginTop: 15,height: 81 }} />
                                ))}
                            </div>}
                        </div>
                        <p className='t1 borderbot1'>评论 {comment_num || 0}</p>
                        {/* 评论列表 */}
                        {comment.map((item, index) => (
                            <div className='plitem' style={{ minHeight: 30 }}>
                                {/* 展开、收起 */}
                                <input type="checkbox" id={`exp${index}`} className='exp' />
                                {/* 内容部分 */}
                                <div className='textLsjc'>
                                    <div className='flex' key={String(index)} style={{ wordBreak: 'break-word' }}>
                                        <div className='avatar' style={{ width: 48, height: 48, }}>
                                            <img alt='' src={item.comment_user.avatar || ''} style={{ width: 48, height: 48, borderRadius: '100%' }} />
                                        </div>
                                        <div className='rbox'>
                                            <div className='flexCenter'>
                                                <h2 className='p5' style={{ fontSize: 16, flex: 1, }}>{item.comment_user.name || item.comment_user.true_name}</h2>
                                                {/* <img alt='' src={require('../../imgs/huif.png').default} style={{ display: 'block', width: 14 }} />
                                                <p className='p4'>12</p> */}
                                                <img alt='' src={require('../../imgs/zans.png').default} style={{ display: 'block', width: 14, marginLeft: 15 }} />
                                                <p className='p4'>{item.zan_num || 0}</p>
                                            </div>
                                            <p className='p5' style={{ fontSize: 14, }}>{item.content || ''}</p>
                                            <p className='p4'>{item.atime && Helper.timestampFormat(item.atime)}</p>
                                            {/* 二级评论 */}
                                            {item.children.map((row, k) => (
                                                <div className='flex' style={{ marginTop: 10 }} key={String(k)}>
                                                    <div className='avatar' style={{ width: 30, height: 30, }}>
                                                        <img alt='' src={row.comment_user.avatar || ''} style={{ width: 30, height: 30, borderRadius: '100%' }} />
                                                    </div>
                                                    <div className='rbox'>
                                                        <div className='flexCenter'>
                                                            <h2 className='p5' style={{ fontSize: 16, flex: 1, }}>{row.comment_user.name || row.comment_user.true_name}</h2>
                                                            {/* <img alt='' src={require('../../imgs/huif.png').default} style={{ display: 'block', width: 14 }} />
                                                            <p className='p4'>12</p> */}
                                                            <img alt='' src={require('../../imgs/zans.png').default} style={{ display: 'block', width: 14, marginLeft: 15 }} />
                                                            <p className='p4'>{row.zan_num || 0}</p>
                                                        </div>
                                                        <p className='p5' style={{ fontSize: 14, }}>{row.content || ''}</p>
                                                        <p className='p4'>{row.atime && Helper.timestampFormat(row.atime)}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* 展开、收起标签 */}
                                    {item.children.length > 0 && <span><label className="jyfwbtn" for={`exp${index}`}></label></span>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <img alt='' src={require('../../imgs/shouj.png').default} className='boximg' />
            </div>
        )
    }
}
