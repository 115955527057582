import React from 'react';
import { Upload as AntUpload,message } from 'antd';

export default class Upload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: props.fileList || [],
            visible: '',  //1--七牛  2--阿里oss  3--腾讯
            action: '',
            fileKey: '',
            domain: '',
            // 七牛配置
            token: '',
            // 阿里oss配置
            OSSAccessKeyId: '',
            policy: '',
            signature: '',
            // 腾讯云配置
            Bucket: '',
            ExpiredTime: '',
            Region: '',
            SecurityToken: '',
            StartTime: '',
            TmpSecretId: '',
            TmpSecretKey: '',
            path: '',
            // 本地配置
            uptoken: ''
        }
    }

    componentDidMount() {
        this.initToken();
    }
    initToken() {
        global.Ajax.hhtc_request("setting/getUploadToken", {}).then(data => {
            if (data.data.visible == 1) {
                data.data.action = "https://up-z2.qiniup.com"
            } else if (data.data.visible == 2) {
                data.data.action = data.data.domain
            } else if (data.data.visible == 3) {
                data.data.action = data.data.path;
            } else if (data.data.visible == 4) {
                data.data.action = global.httpUrl + '/upload'
            }
            this.setState(data.data)
        })
    }
    getFileType(file) {
        var type = 7, houz = '';
        var nameList = file.name && file.name.split('.');
        houz = nameList[nameList.length - 1];
        if (file.type == 'application/vnd.ms-excel' || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || houz == 'xls' || houz == 'xlsx') {
            type = 3
        } else if (file.type == 'application/zip' || file.type == 'application/rar' || houz == 'rar' || houz == 'rar4') {
            type = 6;
        } else if (file.type == 'application/pdf') {
            type = 5;
        } else if (houz == 'doc' || houz == 'docx') {
            type = 4;
        } else if (file.type == 'video/mp4' || houz == 'avi' || houz == 'flv' || file.type=='audio/mpeg' || houz=='mp3') {
            type = 2;
        } else if (houz == 'jpg' || houz == 'jpeg' || houz == 'png' || houz == 'gif') {
            type = 1;
        }
        return type;
    }
    render() {
        return (
            <AntUpload
                ref="upd"
                fileList={this.state.fileList}
                supportServerRender={true}
                action={this.state.action}
                showUploadList={false}  //是否展示文件列表
                headers={{
                    token: this.state.uptoken
                }}
                data={(file) => {
                    var houzui = '.' + file.name.split('.')[file.name.split('.').length - 1];
                    var key = Date.now() + Math.floor(Math.random() * (999999 - 100000) + 100000) + '1' + houzui;
                    var data = {};
                    if (this.state.visible == 1) {  //七牛
                        data = {
                            token: this.state.token,
                            key: key,
                        }
                    } else if (this.state.visible == 2) {  //阿里oss
                        data = {
                            name: file.name,
                            key: key,
                            policy: this.state.policy,
                            OSSAccessKeyId: this.state.OSSAccessKeyId,
                            signature: this.state.signature,
                        }
                    } else if (this.state.visible == 3) {  //腾讯云
                        data = {
                            name: file.name,
                            key: key,
                        }
                    }
                    this.setState({
                        fileKey: key,
                    })
                    return data
                }}
                onChange={(e) => {
                    if(e.file.status=='uploading'){
                        this.props.onPercent(e.file.percent)
                    }
                    var fileList = e.fileList
                    this.setState({
                        fileList
                    }, () => {
                        if (e.file.status == 'done') {
                            this.initToken();
                            if (typeof this.props.onOk === "function") {
                                var type = this.getFileType(e.file),
                                    filePosition = this.state.visible,
                                    key = '';
                                if (this.state.visible == 1) {
                                    key = e.file.response.key;
                                } else if (this.state.visible == 2) {
                                    key = this.state.fileKey;
                                }
                                if(this.state.visible==4){
                                    var url=global.httpUrl+'/'+e.file.response.data.filename
                                    this.props.onLocalOk(url,e.file.response.data.filename,type,e.file.response.data.filename)
                                }else{
                                    this.props.onOk(this.state.domain, key, e.file.name, type, filePosition)
                                    this.setState({
                                        fileKey: ''
                                    })
                                    this.initToken();
                                }
                                // this.props.onOk(this.state.domain, key, e.file.name, type, filePosition)
                                // this.setState({
                                //     fileKey: ''
                                // })
                            }
                        }
                    })

                    if (this.props.onAdd) {
                        this.props.onAdd(fileList)
                    }
                    return true;
                }}
                customRequest={this.state.visible == 3 ? this.txUpload.bind(this) : false}
                {...this.props}
            ></AntUpload>
        );
    }
    txUpload(row) {
        var cos = new window.COS({
            getAuthorization:  (options, callback) => {
                callback({
                    TmpSecretId: this.state.TmpSecretId,
                    TmpSecretKey: this.state.TmpSecretKey,
                    SecurityToken: this.state.SecurityToken,
                    StartTime: this.state.StartTime,
                    ExpiredTime: this.state.ExpiredTime,
                });
            }
        });
        cos.putObject({
            Bucket: this.state.Bucket,
            Region: this.state.Region,
            Key: row.data.key,
            StorageClass: 'STANDARD',
            Body: row.file,
            onProgress: (progressData) => {
                this.props.onPercent(progressData.percent*100)
            }
        }, (err, data) => {
            if (err) {
                console.log(err)
                message.error("上传失败")
            } else {
                this.initToken();
                var type = this.getFileType(row.file)
                this.props.onOk(this.state.path, row.data.key, row.data.name, type, 3)
            }
        });
    }
    getUploadToken(e) {
        var json = {
            token: this.state.token,
            key: e.uid
        }
        return json;
    }
}