import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin, Form, Input as AntInput, } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import AddType from './AddType';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

export default class JobType extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			name: '',
			editData: {},
			reqUrl: "config/addJobType",
			actType: "add",
			// type: 4,
			pid: [0],
			list: [],
			activeIds: [],
			requestLoadingShow: false,
			activeId: 0,
			selectData: [],
			type: null,
		}
		this.columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '类型名称',
				dataIndex: 'name',
				key: 'name',
				align: 'center'
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							<p onClick={this.edit.bind(this, item)}>编辑</p>
							<p onClick={this.del.bind(this, item)}>删除</p>
						</div>
					)
				}
			}
		]
	}
	componentDidMount() {
		this.getList(0)
	}

	refresh() {
		// this.tabblelist.onRefresh()
		let index = this.state.pid.length > 0 ? this.state.pid.length - 1 : 0;
		let list=this.state.list;
		if(this.state.type!='sec'){
			index=0;
		}
		list[index]=[];
		this.setState({
			list,
		}, () => {
			this.getList(index)
		})
	}
	getList(index) {
		this.setState({
			requestLoadingShow: true,
		}, () => {
			global.Ajax.hhtc_request('config/jobType', { pid: this.state.pid[index] }).then(res => {
				if (res.code == 1) {
					var list = this.state.list;
					list[index] = res.data;
					this.setState({
						list
					})
				}
				this.setState({
					requestLoadingShow: false,
				})
			})
		})
	}
	// getList(info, callback) {
	// 	var req = {
	// 		page: info.page,
	// 		size: info.size,
	// 		name: this.state.name,
	// 		orderBy: info.orderBy,
	// 		type: this.state.type
	// 	}
	// 	global.Ajax.hhtc_request('config/jobType', req).then(res => {
	// 		callback(res)
	// 	})
	// }
	// 编辑
	// edit(data) {
	// 	this.setState({
	// 		editData: data,
	// 		actType: 'edit',
	// 		visible: true,
	// 		reqUrl: "config/editJobType"
	// 	})
	// }
	edit(row, index, e) {
		e.preventDefault()
		e.stopPropagation()
		var selectData=[];
		if(index>0){
		    selectData=this.initData(this.state.list[index-1])
		}
		console.log(selectData);
		this.setState({
			actType: 'edit',
			type: 'sec',
			visible: true,
			id: row.id,
			name: row.name,
			selectData,
			activeId: row.pid,
		},()=>{
			setTimeout(() => {
				this.formRef.setFieldsValue({
					sort: row.sort,
					pid: row.pid,
					name: row.name,
				})
			}, 100);
		})
	}
	// 删除
	del(data, e) {
		e.preventDefault();
		e.stopPropagation();
		var url = 'config/delJobType', req = { id: data.id }
		this.delmask.del(url, req)
		console.log(this.state.pid);
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	selectRow(index, item) {
		var pid = this.state.pid,
			activeIds = this.state.activeIds,
			list = this.state.list;
		if (pid.length > index) {
			pid = pid.slice(0, index)
			list = list.slice(0, index)
		}
		if (activeIds.length > index - 1) {
			activeIds = activeIds.slice(0, index - 1)
		}
		pid[index] = item.id;
		activeIds[index - 1] = item.id;
		this.setState({
			pid,
			activeIds,
			list
		}, () => {
			this.getList(index)
		})
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入类型名称'
						className='borderbai'
						onChange={(e) => {
							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}
					/>
					<Button type='primary' onClick={() => {
						this.setState({
							type: 'first',
							visible: true,
							activeId: 0,
						})
						// this.setState({
						// 	visible: true,
						// 	reqUrl: "config/addJobType",
						// 	actType: "add"
						// })
					}}>新增职务类型</Button>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>职务类型列表</h2>
					<div className='perlist'>
						{this.state.list.map((item, index) => (
							<div className='item' key={String(index)}>
								{item.map((row, k) => (
									<div className={`row flexCenter ${row.id == this.state.activeIds[index] ? 'active iconfont' : ''}`} key={String(k)}
										onClick={() => {
											if (row.pid == 0) {
												this.selectRow(index + 1, row)
											}
										}}>
										<p className='p1' title={row.name}>{row.name}</p>
										{row.pid == 0 && <span className='iconfont icon-tianjia2' onClick={this.add.bind(this, row.id, item)}></span>}
										<span className='iconfont icon-bianji' onClick={this.edit.bind(this, row, index)}></span>
										<span className='iconfont icon-shanchu' onClick={this.del.bind(this, row)}></span>
									</div>
								))}
								{/* 空数据 */}
								{item.length == 0 && <Empty style={{ marginTop: 30 }} />}
							</div>
						))}
						{this.state.requestLoadingShow && <Spin tip="加载中..." style={{ marginTop: 40, marginLeft: 100, verticalAlign: 'top' }} />}
					</div>
					{/* <CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
					/> */}
				</div>
				{/* 添加 */}
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={`${this.state.actType == 'edit' ? '编辑' : '添加'}职务类型`}
					onCancel={this.onCancel.bind(this)}
				>
					<Form
						onFinish={this.onFinish}
						labelCol={{ flex: '82px' }}
						ref={dom=>this.formRef=dom}
					>
						{(this.state.type == 'sec') && <Form.Item
							name='pid'
							label='上级类型'
							rules={[{ required: true, message: '请选择上级类型' }]}
							initialValue={this.state.activeId}
						>
							<SelectList
								data={this.state.selectData}
								placeholder='请选择上级类型'
							/>
						</Form.Item>}
						<Form.Item
							name='name'
							label='类型名称'
							rules={[{ required: true, message: '请输入专业领域名称' }]}
							// initialValue={this.state.name}
						>
							<AntInput autoComplete='off' placeholder='请输入专业领域名称' />
						</Form.Item>
						<Form.Item name="sort" label="排序序号" rules={[{ required: false, message: '请输入序号!' }]}>
							<AntInput autoComplete="off" defaultValue={0} placeholder='请输入序号!' type='number' />
						</Form.Item>
						<Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
					</Form>
					{/* <AddType data={this.state.editData} reqUrl={this.state.reqUrl} type={this.state.type} actType={this.state.actType} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} /> */}
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
			</React.Fragment>
		)
	}
	onFinish = (req) => {
		this.setState({
			loading: true,
		}, () => {
			req.sort = req.sort || 0;
			req.type = 4;
			let url = 'config/addJobType';
			if (this.state.actType == 'edit') {
				req.id = this.state.id;
				url = 'config/editJobType';
			}
			global.Ajax.hhtc_request(url, req).then(res => {
				if (res.code == 1) {
					message.success(res.msg, 1.2)
					this.onCancel();
					this.refresh()
				} else {
					message.error(res.msg, 1.2)
				}
				this.setState({ loading: false, })
			})
		})
	}
	add(id, data, e) {
		e.preventDefault()
		e.stopPropagation()
		this.setState({
			type: 'sec',
			visible: true,
			activeId: id,
			selectData: this.initData(data),
		})
	}
	initData(arry) {
		let arryNew = []
		arry.map((item, index) => {
			arryNew.push(Object.assign({}, item, { value: item.id, label: item.name }))
		})
		return arryNew
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			editData: {},
			// type: null,
			activeId: null,
			selectData: [],
			name: null,
			actType: '',
		})
	}
}
