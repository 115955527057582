import React from 'react';
import { Button, Pagination, Form, message, Select, Image } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';
import UserCharge from './UserRecharge';
import moment from 'moment';
import TrueNameInfo from './TrueNameInfo';
import UserResume from './UserResume';  //用户简历
import JobInfo from './JobInfo';  //职务详情

export default class User extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			is_auth: -1,
			is_resume: -1,
			name: "",
			editData: {},
			true_name_visible: false,
			true_name_info: {}
		}
		this.columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '头像',
				dataIndex: 'avatar',
				key: 'avatar',
				align: 'center',
				render: (avatar) => {
					return (
						<Image src={avatar} className='avatar' width={40} height={40} />
					);
				}
			}, {
				title: '姓名',
				dataIndex: 'nickname',
				key: 'nickname',
				align: 'center',

			}, {
				title: '手机号',
				dataIndex: 'mobile',
				key: 'mobile',
				align: 'center',
				render: (phone) => (
					phone || "-"
				)
			}, {
				title: '性别',
				dataIndex: 'sex',
				key: 'sex',
				align: 'center',
				render: (sex) => {
					if (sex == 0) {
						return "未知"
					} else if (sex == 1) {
						return "男"
					} else {
						return "女"
					}
				}
			}, {
				title: '实名认证',
				dataIndex: 'is_auth',
				key: 'is_auth',
				align: 'center',
				render: (is_auth, info) => {
					if (is_auth == 1) {
						return (<p onClick={() => {
							this.setState({
								true_name_info: info,
								true_name_visible: true
							})
						}} className='color1 pointer'>查看实名信息</p>);
					} else {
						return "未实名";
					}
				}
			}, {
				title: '用户简历',
				dataIndex: 'is_resume',
				key: 'is_resume',
				align: 'center',
				render: (is_resume, info) => {
					if (is_resume == 1) {
						return (<p className='color1 pointer' onClick={() => {
							this.setState({
								resumeVisible: true,
								editData: info
							})
						}}>查看简历</p>);
					} else {
						return "未完善";
					}
				}
			}, {
				title: 'VIP',
				dataIndex: 'vip_level',
				key: 'vip_level',
				align: 'center',
				render: (vip_level, item) => {
					if (vip_level > 1) {
						var time = item.vip_expire_time - parseInt(moment().valueOf() / 1000);
						var dayTime = 3600 * 24;
						var day = Math.ceil((time / dayTime))
						var info = "";
						if (day < 1) {
							info = "一天内到期";
						} else {
							info = "剩余" + day + "天";
						}
						return (<p className='color1'>{info}</p>);
					} else {
						return "非VIP";
					}
				}
			}, {
				title: '用户状态',
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				render: (status) => {
					if (status == 1) {
						return <p className='color1'>正常</p>;
					} else {
						return <p className='color3'>已冻结</p>;
					}
				}
			}, {
				title: '注册时间',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center',

			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							<p className='color1' onClick={this.edit.bind(this, item)}>充值VIP</p>
							{item.status == 1 && <p className='color3' onClick={this.del.bind(this, item)}>冻结</p>}
							{item.status == 0 && <p className='color1' onClick={this.del.bind(this, item)}>解冻</p>}
							<p className='color3' onClick={this.del1.bind(this, item)}>删除</p>
						</div>
					)
				}
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy: info.orderBy,
			is_auth: this.state.is_auth,
			is_resume: this.state.is_resume
		}
		global.Ajax.hhtc_request('user/userList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			visible: true
		})
	}
	// 删除
	del(data) {
		var url = 'user/userEnable', req = { user_id: data.user_id }
		this.delmask.del(url, req, "确定要" + (data.status == 1 ? "冻结" : "解冻") + "用户吗?")
	}
	del1(data) {
		var url = 'user/delUser', req = { user_id: data.user_id }
		this.delmask.del(url, req, "确定要删除该用户吗?")
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入姓名或者手机号查询'
						className='borderbai'
						onChange={(e) => {

							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
					<Select
						allowClear
						className='marginr12'
						bordered={false}
						style={{
							width: 150, background: '#fff'
						}}
						onChange={(e) => {
							if (e == undefined) {
								e = -1;
							}
							this.setState({
								is_resume: e
							}, () => {
								this.refresh();
							})
						}}
						placeholder="是否完善简历"
						options={[
							{
								value: 0,
								label: '未完善',
							},
							{
								value: 1,
								label: '已完善',
							}
						]}
					/>
					<Select
						allowClear
						className='marginr12'
						bordered={false}
						style={{
							width: 150, background: '#fff'
						}}
						onChange={(e) => {
							if (e == undefined) {
								e = -1;
							}
							this.setState({
								is_auth: e
							}, () => {
								this.refresh();
							})
						}}
						placeholder="是否实名认证"
						options={[
							{
								value: 0,
								label: '未实名',
							},
							{
								value: 1,
								label: '已实名',
							}
						]}
					/>
					{/* <SelectList
						type="allrole"
						className='marginr12 borderbai'
						style={{ width: 160 }}
						onChange={(role_id) => {
							this.setState({
								role_id:role_id||0,
							}, () => {
								this.refresh()
							})
						}}
					/> */}

				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>用户列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'

					/>
				</div>
				{/* 添加管理员 */}
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={"充值VIP"}
					onCancel={this.onCancel.bind(this)}
				>
					<UserCharge data={this.state.editData} reqUrl={"user/userRecharge"} type={1} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
				{/* 查看实名信息 */}
				<CustomModal
					visible={this.state.true_name_visible}
					width={360}
					title={"实名信息"}
					onCancel={this.onCancel.bind(this)}
				>
					<TrueNameInfo data={this.state.true_name_info} />
				</CustomModal>
				{/* 查看简历 */}
				<CustomModal
					visible={this.state.resumeVisible}
					width={388}
					closable={false}
					className='ginfobox'
					onCancel={this.onCancel.bind(this)}
				>
					<UserResume data={this.state.editData} />
				</CustomModal>
				{/* 查看职务详情 */}
				<CustomModal
					visible={this.state.jobVisible}
					width={388}
					closable={false}
					className='ginfobox'
					onCancel={this.onCancel.bind(this)}
				>
					<JobInfo />
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			true_name_visible: false,
			editData: {},
			resumeVisible: false,
			jobVisible: false,
		})
	}
}
