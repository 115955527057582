import React from 'react';
import { Input, Button, Form, message } from 'antd';
import SelectList from '../../common/SelectList'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pwd: ''
        }
    }
    checkPwd=(value)=>{
        if (this.props.type != 'edit') {
            if(this.state.pwd==''){
                return Promise.reject(new Error('登录密码不能为空!'));
            }
        }
        return Promise.resolve();
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        },()=>{
            var url='admin/addAdmin';
            if(this.props.type=='edit'){  //编辑
                url='admin/editAdmin';
                req.admin_id=this.props.data.admin_id
            }
            global.Ajax.hhtc_request(url,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 6}}
                    labelAlign="left"
                    initialValues={{
                        username: this.props.data.username || undefined,
                    }}
                >
                    <Form.Item name="username" label="用户昵称：" rules={[{ required: true, message: '请输入用户昵称!' }]}>
                        <Input autoComplete="off" placeholder='请设置6-12位用户昵称' type='text' />
                    </Form.Item>
                    <Form.Item name="password" label="登录密码：" rules={[{ validator: this.checkPwd }]}>
                        <Input autoComplete="new-password" placeholder='请设置新密码' type='password' onChange={(e)=>{
                            this.setState({
                                pwd: e.target.value,
                            })
                        }} />
                    </Form.Item>
                    <Form.Item initialValue={this.props.data.role_id} name="role_id" label="角色：" rules={[{ required: true, message: '请选择角色!' }]}>
                        <SelectList type="allrole" style={{ flex: 1 }} />
                    </Form.Item>
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}