import React from 'react';
import { Pagination,Spin,Empty } from 'antd'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data || [],
            total: props.total || 0,
            page: props.page || 1,
            size: props.size || 10,
			requestLoadingShow: true,
        }
    }
    componentDidMount(){
        this.getList()
    }
    getList(){
        this.setState({
            requestLoadingShow: true,
            data: []
        },()=>{
            this.props.onRefresh(this.state.page,(res)=>{
                this.setState({
                    total: res.all,
                    data: res.datas,
                    requestLoadingShow: false,
                })
            })
        })
    }
    renderItem(){
        return this.props.renderItem(this.state.data)
    }
    // 刷新
    onRefresh(){
        this.setState({
            page: 1,
        },()=>{
            this.getList()
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className={`pubList ${this.props.className}`}>
                    {/* 表头 */}
                    {this.props.renderHead()}
                    {/* 表内容 */}
                    {!this.state.requestLoadingShow&&this.renderItem()}
                    {/* {this.props.renderItem()} */}
                    {/* 加载中 */}
                    {this.state.requestLoadingShow && <Spin tip="加载中..." style={{ display: 'block', margin: '100px auto 0' }} />}
                    {/* 暂无数据 */}
                    {this.state.total == 0 && !this.state.requestLoadingShow && <Empty style={{ marginTop: 100 }} />}
                </div>
                {this.state.total > 0 && <Pagination
                    total={this.state.total}
                    current={this.state.page}
                    pageSize={this.state.size}
                    showTotal={(total, range) => {
                        var num=range[0],
                            num1=range[1]
                        num=num<10?('0'+num):num;
                        num1=num1<10?('0'+num1):num1;
                        return `共${total}条记录，本页展示${num}-${num1}条记录`
                    }}
                    onChange={(page) => {
                        this.setState({
                            page,
                        }, () => {
                            this.getList()
                        })
                    }}
                    showSizeChanger={false}
                />}
            </React.Fragment>
        )
    }
}