import React from 'react';
import { Button, Tooltip, Modal } from 'antd'
import AddRole from './AddRole';  //添加角色
import TabbleList from '../../common/TabbleList';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			visible: false,
			orderBy: '',
			row: {
				ids: '[]',
				role_name: '',
				describe: ''
			},
		}
		this.columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',sorter: true,
				align: 'center'
			}, {
				title: '角色名称',
				dataIndex: 'role_name',
				key: 'role_name',
				align: 'center'
			}, {
				title: '角色描述',
				dataIndex: 'describe',
				key: 'describe',
				align: 'center'
			}, {
				title: '添加时间',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center'
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							<p onClick={this.edit.bind(this, item)}>编辑</p>
							<p onClick={this.del.bind(this, item)}>删除</p>
						</div>
					)
				}
			}
		]
	}
	refresh(){
		this.tabblelist.onRefresh()
	}
	changeVisible(key) {
		this.setState({
			[key]: true
		})
	}
	getList(info, callback) {
		
		var req = {
			page: info.page,
			size: info.size,
			orderBy: info.orderBy,
		}
		global.Ajax.hhtc_request('role/roleList', req).then(res => {
			callback(res)
		})
	}

	// 编辑
	edit(data) {
		this.setState({
			type: 'edit',
			visible: true,
			row: data,
		})
	}
	// 删除
	del(data) {
		var url = 'role/delRole', req = { role_id: data.role_id }
		this.delmask.del(url, req)
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	render() {
		return (
			<React.Fragment>
				<Button type='primary' onClick={this.changeVisible.bind(this, 'visible')}>添加角色</Button>
				<div className='bgbai'>
					<h2 className='pubTit'>角色列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
						
					/>
				</div>
				{/* 添加角色 */}
				<CustomModal
					visible={this.state.visible}
					width={900}
					title={`${this.state.type == 'edit' ? '编辑' : '添加'}角色`}
					onCancel={this.onCancel.bind(this)}
				>
					<AddRole type={this.state.type} data={this.state.row} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
			</React.Fragment>
		)
	}
	onOk() {
		this.onCancel()
		this.refresh()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			row: {
				ids: '[]',
				role_name: '',
				describe: ''
			}
		})
	}
}
