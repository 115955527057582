import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import AddAdmin from './AddAdmin';  //添加管理员
import TabbleList from '../../common/TabbleList';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			visible: false,
			orderBy: '',
			username: '',
			role_id: '',
			type: '',
			editData: {},
			reqFinished: false,
		}
		this.columns = [
			{
				title: '序号',
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '用户昵称',
				dataIndex: 'username',
				key: 'username',
				align: 'center'
			}, {
				title: '上次登录时间',
				dataIndex: 'last_login_time',
				key: 'last_login_time',
				render: last_login_time => `${last_login_time || '-'}`,
				align: 'center'
			}, {
				title: '上次登录IP',
				dataIndex: 'last_login_ip',
				key: 'last_login_ip',
				render: last_login_ip => `${last_login_ip || '-'}`,
				align: 'center'
			}, {
				title: '角色',
				dataIndex: 'role_name',
				key: 'role_name',
				align: 'center'
			}, {
				title: '添加时间',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center'
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							<p onClick={this.edit.bind(this, item)}>编辑</p>
							<p onClick={this.del.bind(this, item)}>删除</p>
						</div>
					)
				}
			}
		]
	}
	changeVisible(key) {
		this.setState({
			[key]: true
		})
	}
	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			username: this.state.username,
			role_id: this.state.role_id,
			orderBy:info.orderBy
		}
		global.Ajax.hhtc_request('admin/adminList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			type: 'edit',
			visible: true,
		})
	}
	// 删除
	del(data) {
		var url = 'admin/delAdmin', req = { admin_id: data.admin_id }
		this.delmask.del(url, req)
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入用户昵称'
						className='borderbai'
						onChange={(e) => {
							
							this.setState({
								username: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
					<SelectList
						type="allrole"
						className='marginr12 borderbai'
						style={{ width: 160 }}
						onChange={(role_id) => {
							this.setState({
								role_id:role_id||0,
							}, () => {
								this.refresh()
							})
						}}
					/>
					<Button type='primary' onClick={this.changeVisible.bind(this, 'visible')}>添加管理员</Button>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>管理员列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
						
					/>
				</div>
				{/* 添加管理员 */}
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={`${this.state.type == 'edit' ? '编辑' : '添加'}管理员`}
					onCancel={this.onCancel.bind(this)}
				>
					<AddAdmin data={this.state.editData} type={this.state.type} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			editData: {}
		})
	}
}
