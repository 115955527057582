import React from 'react';
import { Input, Button, Form, message ,InputNumber} from 'antd';


export default class TrueNameInfo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 6}}
                    labelAlign="left"
                    initialValues={this.props.data}
                >
                    
                    <Form.Item name="true_name"  label="真实姓名" >
                               <Input autoComplete="off" disabled  type='text' />
                           </Form.Item>
                           <Form.Item name="id_card"  label="身份证号" >
                               <Input autoComplete="off" disabled  type='text' />
                           </Form.Item>
                           <Form.Item name="mobile"  label="电话号码" >
                               <Input autoComplete="off" disabled  type='text' />
                           </Form.Item>
                </Form>
            </div>
        )
    }
}