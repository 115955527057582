import React from 'react';
import { SketchPicker } from 'react-color';
import { ConfigProvider, Button, Carousel, Tooltip } from 'antd'


export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            primaryColor: '#1890ff',
            // headingColor: '#ff4d4f',
            successColor: '#52c41a',
            warningColor: '#faad14',
            infoColor: '#ff4d4f',
            checkprimaryColor: '#1890ff',
            checksuccessColor: '#52c41a',
            checkwarningColor: '#faad14',
            checkinfoColor: '#ff4d4f',
            colors: [
                { primaryColor: '#05CB94', successColor: '#0081FF', warningColor: '#FF5722', infoColor: '#FFB800' },
                { primaryColor: '#FF7500', successColor: '#0081FF', warningColor: '#FF5722', infoColor: '#FFB800' },
                { primaryColor: '#0081FF', successColor: '#05CB94', warningColor: '#FF5722', infoColor: '#FFB800' },
                { primaryColor: '#FE2121', successColor: '#0081FF', warningColor: '#FF5722', infoColor: '#FFB800' },
                { primaryColor: '#37B7A5', successColor: '#319EEB', warningColor: '#FF895F', infoColor: '#F3C10A' },
            ],
            activeIndex: null,
            trans: ''
        }
    }
    componentDidMount() {
        this.getColor()
    }
    getColor() {
        var mergedNextColor = JSON.parse(localStorage.getItem('honghuTheme'))
        console.log(mergedNextColor)
        this.setState(mergedNextColor)
    }
    onColorChange(nextColor) {
        // console.log(nextColor)
        const color = this.state
        const mergedNextColor = {
            ...color,
            ...nextColor,
        };
        this.setState(nextColor)
        // console.log(mergedNextColor)
        // localStorage.setItem('honghuTheme', JSON.stringify(mergedNextColor))
        // ConfigProvider.config({
        //     theme: mergedNextColor,
        // });
    }
    save() {
        this.setState({
            checkprimaryColor: this.state.primaryColor,
            checksuccessColor: this.state.successColor,
            checkwarningColor: this.state.warningColor,
            checkinfoColor: this.state.infoColor
        })
    }

    onOk() {
        const color = this.state
        // color.primaryColor=color.checkprimaryColor;
        // color.successColor=color.checksuccessColor;
        // color.warningColor=color.checkwarningColor;
        // color.infoColor=color.checkinfoColor;
        // this.setState(color)
        const mergedNextColor = {
            ...color,
        };
        localStorage.setItem('honghuTheme', JSON.stringify(mergedNextColor))
        ConfigProvider.config({
            theme: mergedNextColor,
        });
        this.props.onOk()
    }
    goTo(type){
        var index=this.state.activeIndex;
        if(type=='prev'){
            if(index==null || index==0){
                return
            }
        }
        if(type=='next'){
            if(index==this.state.colors.length-1){
                return
            }
        }
        if(index==null){
            index=0
        }else{
            if(type=='prev'){
                index--
            }else{
                index++
            }
        }
        var num=index-2<1?0:index-2;
        var dis=256*num;
        this.setState({
            trans: `translate3d(-${dis}px,0px,0px)`,
            activeIndex: index,
        })
    }
    render() {
        const color = this.state;
        return (
            <div className='setColorBox'>
                <div className='cont'>
                    <div className='left'>
                        <div className='ltop'>
                            <div className='zuo'>
                                <div className='flexCenter'>
                                    <p style={{ width: 30, height: 30, marginRight: 8, marginLeft: 20, background: this.state.checkprimaryColor }}></p>
                                    <p style={{ flex: 1, height: 30 }}></p>
                                </div>
                                {[1, 1, 1, 1, 1, 1].map((item, index) => (
                                    <div className='flexCenter' key={'' + index}>
                                        <p style={{ width: 2, height: 24, background: this.state.checkprimaryColor }}></p>
                                        <p style={{ width: 16, margin: '0 8px 0 20px' }}></p>
                                        <p style={{ flex: 1 }}></p>
                                    </div>
                                ))}
                            </div>
                            <div className='you'>
                                <div className='top' style={{ display: 'flex', alignItems: 'center', height: 70, background: '#fff', paddingRight: 20 }}>
                                    <div className='flexCenter'>
                                        <p style={{ width: 16, margin: '0 8px 0 20px' }}></p>
                                        <p style={{ width: 200 }}></p>
                                    </div>
                                    <div className='flexCenter' style={{ marginLeft: 'auto' }}>
                                        <p style={{ width: 30, height: 30, borderRadius: '100%', margin: '0 8px 0 20px' }}></p>
                                        <p style={{ width: 60 }}></p>
                                    </div>
                                </div>
                                <div className='neir'>
                                    <p style={{ width: 60, height: 24, background: this.state.checkprimaryColor }}></p>
                                    <div className='exap'>
                                        {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                                            <div className='flexCenter' style={{ marginTop: 20 }} key={'' + index}>
                                                <p style={{ flex: 1 }}></p>
                                                <p style={{ width: 48, height: 20, marginLeft: 8, background: this.state.checkprimaryColor }}></p>
                                                <p style={{ width: 48, height: 20, marginLeft: 8, background: this.state.checksuccessColor }}></p>
                                                <p style={{ width: 48, height: 20, marginLeft: 8, background: this.state.checkwarningColor }}></p>
                                                <p style={{ width: 48, height: 20, marginLeft: 8, background: this.state.checkinfoColor }}></p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lbot'>
                            <p className='iconfont icon-zuo' style={{ background: this.state.activeIndex<1?'#E6E6E6':this.state.checkprimaryColor }} onClick={this.goTo.bind(this,'prev')}></p>
                            <div className='kkxbox' style={{}}>
                                <div ref={dom=>this.slider=dom} style={{width: 256*this.state.colors.length,transform: this.state.trans,transition: '.4s'}}>
                                    {this.state.colors.map((item, index) => (
                                        <div className='kkx' style={{borderColor: this.state.activeIndex==index?this.state.checkprimaryColor:''}} key={'' + index} onClick={() => {
                                            this.setState({
                                                checkprimaryColor: item.primaryColor,
                                                checksuccessColor: item.successColor,
                                                checkwarningColor: item.warningColor,
                                                checkinfoColor: item.infoColor,
                                                primaryColor: item.primaryColor,
                                                successColor: item.successColor,
                                                warningColor: item.warningColor,
                                                infoColor: item.infoColor,
                                                activeIndex: index,
                                            })
                                        }}>
                                            <p>预设方案{index + 1}：</p>
                                            <div className='flexCenter'>
                                                <p style={{ background: item.primaryColor }}>主</p>
                                                <p style={{ background: item.successColor }}>辅1</p>
                                                <p style={{ background: item.warningColor }}>辅2</p>
                                                <p style={{ background: item.infoColor }}>辅3</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <p className='iconfont icon-you' style={{ background: this.state.activeIndex==this.state.colors.length-1?'#E6E6E6':this.state.checkprimaryColor }} onClick={this.goTo.bind(this,'next')}></p>
                        </div>
                    </div>
                    <div className='right'>
                        <h2>自定义配色方案：</h2>
                        <div className='flexCenter'>
                            <p>主色：</p>
                            <div className='flexCenter inpt'>
                                <Tooltip
                                    placement='bottom'
                                    trigger='click'
                                    overlayClassName='colorTool'
                                    title={<SketchPicker
                                        width={256}
                                        color={color.primaryColor}
                                        onChange={({ hex }) => {
                                            this.onColorChange({
                                                primaryColor: hex,
                                            });
                                        }}
                                    />}
                                >
                                    <span style={{ background: this.state.primaryColor }}></span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flexCenter'>
                            <p>辅色1：</p>
                            <div className='flexCenter inpt'>
                                <Tooltip
                                    placement='bottom'
                                    trigger='click'
                                    overlayClassName='colorTool'
                                    title={<SketchPicker
                                        width={256}
                                        color={color.successColor}
                                        onChange={({ hex }) => {
                                            this.onColorChange({
                                                successColor: hex,
                                            });
                                        }}
                                    />}
                                >
                                    <span style={{ background: this.state.successColor }}></span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flexCenter'>
                            <p>辅色2：</p>
                            <div className='flexCenter inpt'>
                                <Tooltip
                                    placement='bottom'
                                    trigger='click'
                                    overlayClassName='colorTool'
                                    title={<SketchPicker
                                        width={256}
                                        color={color.warningColor}
                                        onChange={({ hex }) => {
                                            this.onColorChange({
                                                warningColor: hex,
                                            });
                                        }}
                                    />}
                                >
                                    <span style={{ background: this.state.warningColor }}></span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flexCenter'>
                            <p>辅色3：</p>
                            <div className='flexCenter inpt'>
                                <Tooltip
                                    placement='bottom'
                                    trigger='click'
                                    overlayClassName='colorTool'
                                    title={<SketchPicker
                                        width={256}
                                        color={color.infoColor}
                                        onChange={({ hex }) => {
                                            this.onColorChange({
                                                infoColor: hex,
                                            });
                                        }}
                                    />}
                                >
                                    <span style={{ background: this.state.infoColor }}></span>
                                </Tooltip>
                            </div>
                        </div>
                        <Button type='primary' style={{ padding: 0, margin: '40px 0 0 0', width: 55, background: this.state.checksuccessColor, borderColor: this.state.checksuccessColor, marginRight: 12 }} onClick={this.getColor.bind(this)}>重置</Button>
                        <Button type='primary' style={{ padding: 0, margin: '40px 0 0 0', width: 55, background: this.state.checkprimaryColor, borderColor: this.state.checkprimaryColor }} onClick={this.save.bind(this)}>预览</Button>
                    </div>
                </div>
                <div className='btnbox'>
                    <Button className='huibtn' onClick={() => {
                        this.props.onCancel()
                    }}>取消</Button>
                    <Button onClick={this.onOk.bind(this)} type='primary'>确定</Button>
                </div>
            </div>
        )
    }
}
