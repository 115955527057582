import React, { useId } from 'react';
import { Button, Pagination, Modal, message, Select, Image, Form, Input, Switch, Upload } from 'antd'
import CustomTable from '../../common/Table';
import CustomModal from '../../common/Modal';
import Helper from '../../class/Helper';
import UploadList from '../../common/UploadList';
import CustomInput from '../../common/Input';
import Del from '../../common/Del';  //删除弹窗

// function ImageDemo(props) {
//     const [visible, setVisible] = useState(false);
//     return (
//         <React.Fragment>
//             <img alt='' src={props.url} />
//             <div className='ylmask'>
//                 <span className='yl' onClick={(e) => {
//                     e.preventDefault()
//                     e.stopPropagation()
//                     setVisible(true)
//                 }}>预览</span>
//             </div>
//             <Image
//                 src={props.url}
//                 style={{ display: 'none' }}
//                 preview={{
//                     visible,
//                     src: props.url,
//                     onVisibleChange: (value) => {
//                         setVisible(value);
//                     }
//                 }}
//             />
//         </React.Fragment>
//     )
// }
export default class JobList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editData: {},
            levelList: []
        }
        this.columns = [
            {
                title: '序号',
                width: 80,
                dataIndex: 'key',
                key: 'key',
                sorter: true,
                align: 'center'
            }, {
                title: '模板名称',
                dataIndex: 'name',
                key: 'name',
                align: 'center'
            }, {
                title: '模板文件',
                dataIndex: 'file_url',
                key: 'file_url',
                align: 'center',
                className: 'pubname',
                render: url => (
                    <p className='color1' onClick={() => {
                        window.open(url)
                    }}>下载</p>
                )
            }, {
                title: '模板缩略图',
                dataIndex: 'thumb',
                key: 'thumb',
                align: 'center',
                render: url => (
                    <Image src={url} width={50} height={50} />
                )
            }, {
                title: '能否使用',
                dataIndex: 'vip_level',
                key: 'vip_level',
                align: 'center',
                render: level => `Vip等级${level}以上使用`
            }, {
                title: 'Vip等级名称',
                dataIndex: 'vip_level_name',
                key: 'vip_level_name',
                align: 'center',
            }, {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                render: (id, item) => {
                    return (
                        <div className='flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    )
                }
            }
        ]
    }
    componentDidMount() {
        // 获取vip等级
        global.Ajax.hhtc_request('common/getUserVipConfigList', {}).then(res => {
            if (res.code == 1) {
                this.setState({
                    levelList: res.data,
                })
            }
        })
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: info.orderBy,
            name: info.name || '',
            vip_level: info.vip_level || '',
        }
        global.Ajax.hhtc_request('user/resumeTempList', req).then(res => {
            callback(res)
        })
    }
    // 编辑
    edit(data) {
        let fileArr = data.file_url.split('/'),
            file_name = fileArr[fileArr.length - 1];
        this.setState({
            type: 'edit',
            editData: data,
            thumb: data.thumb,
            visible: true,
            file_name
        }, () => {
            setTimeout(() => {
                this.formRef.setFieldsValue(data)
            }, 100);
        })
    }
    // 删除
    del(data) {
        var url = 'user/delResumeTemp', req = { id: data.id }
        this.delmask.del(url, req, "确定要删除此配置吗?")
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Input
                        placeholder='请输入模板名称关键词查询'
                        className='borderbai marginr12'
                        style={{ width: 200 }}
                        onChange={(e) => {
                            this.setState({
                                name: e.target.value
                            }, () => {
                                this.refresh()
                            })
                        }}
                    />
                    <Select options={this.state.levelList} placeholder='请选择Vip等级查询' className='pubSelt borderbai' fieldNames={{ label: 'name', value: 'id' }}></Select>
                    <Button type='primary' style={{ marginLeft: 12 }} onClick={() => {
                        this.setState({
                            type: 'add',
                            visible: true,
                        })
                    }}>新增模板</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>简历模板配置</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                <CustomModal
                    visible={this.state.visible}
                    width={400}
                    title={this.state.type == 'edit' ? '编辑' : '新增'}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        onFinish={this.onFinish}
                        ref={dom => this.formRef = dom}
                        labelCol={{ flex: '96px' }}
                    // onValuesChange={this.onValuesChange}
                    >
                        <Form.Item label='上传模板' name='file_url' rules={[{ required: true, message: '请上传模板！' }]}>
                            <Button type='primary' style={{ marginBottom: 10 }} onClick={() => {
                                this.setState({
                                    fileType: 4,
                                }, () => {
                                    this.uplist.setState({
                                        visible: true,
                                    })
                                })
                            }}>上传Word</Button>
                            {this.state.file_name && <React.Fragment>
                                <span style={{ fontSize: 12, marginLeft: 4, marginRight: 4 }}>{this.state.file_name}</span>
                                <span className='iconfont icon-shanchu cursor' onClick={() => {
                                    this.setState({
                                        file_name: undefined,
                                    })
                                    this.formRef.setFieldsValue({
                                        file_url: undefined,
                                    })
                                }}></span>
                            </React.Fragment>}
                        </Form.Item>
                        <Form.Item label='模板名称' name='name' rules={[{ required: true, message: '请输入模板名称' }]}>
                            <Input autoComplete='off' placeholder='请输入模板名称' />
                        </Form.Item>
                        {/* <Form.Item label='模板路径' name='file_url' rules={[{ required: true, message: '请输入模板路径' }]}>
                            <Input autoComplete='off' placeholder='请输入模板路径' />
                        </Form.Item> */}
                        <Form.Item label='模板缩略图' name='thumb' rules={[{ required: true, message: '请上传模板缩略图' }]}>
                            <Input bordered={false} style={{ display: 'none' }} />
                            <img alt='' className='cursor' src={this.state.thumb || require('../../imgs/add.png').default} style={{ width: 80, height: 80, }} onClick={() => {
                                this.setState({
                                    fileType: 1,
                                }, () => {
                                    this.uplist.setState({
                                        visible: true,
                                    })
                                })
                            }} />
                        </Form.Item>
                        <Form.Item label='能否使用' name='vip_level' rules={[{ required: true, message: '请输入Vip等级' }]}>
                            {/* <Input autoComplete='off' placeholder='请输入Vip等级' /> */}
                            <Select options={this.state.levelList} placeholder='请选择Vip等级查询' fieldNames={{ label: 'name', value: 'id' }}></Select>
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit">确定</Button>
                    </Form>
                    {/* 文件库 */}
                    {this.state.fileType > 0 && <UploadList fileNum={1} ref={dom => this.uplist = dom} type={this.state.fileType} onOk={this.onOk.bind(this)} onCancel={() => {
                        this.setState({
                            fileType: null,
                        })
                    }} />}
                </CustomModal>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
            </React.Fragment>
        )
    }
    onValuesChange = (res) => {
        // var key = Object.keys(res)[0];
        // if (key == 'vip_level') {
        //     var value = Helper.getNumber(res[key]);
        //     this.formRef.setFieldsValue({
        //         [key]: value,
        //     })
        // }
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            let url = 'user/addResumeTemp';
            if (this.state.type == 'edit') {
                url = 'user/editResumeTemp';
                req.id = this.state.editData.id;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2);
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    onOk(data) {
        if (this.state.fileType == 1) {
            this.setState({
                thumb: data[0],
            })
            this.formRef.setFieldsValue({
                thumb: data[0],
            })
        } else if (this.state.fileType == 4) {
            let fileArr = data[0].split('/'),
                file_name = fileArr[fileArr.length - 1];
            this.setState({
                file_name
            })
            this.formRef.setFieldsValue({
                file_url: data[0],
            })
        }
        this.uplist.onCancel()
    }
    onCancel() {
        this.setState({
            visible: false,
            type: null,
            file_name: undefined,
            thumb: undefined,
        })
        this.formRef.setFieldsValue({
            file_url: undefined,
            thumb: undefined,
        })
    }
}
