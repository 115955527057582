import React from 'react';
import { Modal, message } from 'antd'

export default class Upload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    del(url,req,content='您要删除该项数据吗？删除后将无法恢复！') {
		Modal.confirm({
			title: <React.Fragment><span style={{ color: '#FFB800', fontSize: 32, marginRight: 12 }} className='iconfont icon-jinggao'></span>警告提示</React.Fragment>,
			content: content,
			icon: null,
			centered: true,
			className: 'pubConfirm',
			maskClosable: true,
			onOk: () => {
				// 删除
				global.Ajax.hhtc_request(url, req).then(res => {
					if (res.code == 1) {
                        message.success(res.msg, 1.2)
                        this.props.onRefresh()
						// this.tabblelist.onRefresh()
					} else {
						message.error(res.msg, 1.2)
					}
				})
			}
		})
	}
    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}