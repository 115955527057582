import React from 'react';
import { Input, Button, Form, message, InputNumber } from 'antd';
import SelectList from '../../common/SelectList'

export default class AddType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pwd: ''
        }
    }

    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            console.log(this.props)
            if (req.sort === undefined) {
                req.sort = 0;
            }
            req.type = this.props.type
            if (this.props.actType == 'edit') {  //编辑
                req.id = this.props.data.id
            }
            global.Ajax.hhtc_request(this.props.reqUrl, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.props.onOk()
                } else {
                    message.error(res.msg, 1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 6}}
                    labelAlign="left"
                    initialValues={this.props.data}
                >
                    <Form.Item name="name" label={`${(this.props.type!=16&&this.props.type!=13)?'类型':''}名称`} rules={[{ required: true, message: `请输入${this.props.type!=16?'类型':''}名称!` }]}>
                        <Input autoComplete="off" placeholder={`请输入${this.props.type!=16?'类型':''}名称`} type='text' />
                    </Form.Item>
                    {this.props.type==8 && 
                    <Form.Item name="description" label="类型说明" rules={[{ required: true, message: '请输入类型说明!' }]}>
                        <Input autoComplete="off" placeholder='请输入类型说明!' type='text' />
                    </Form.Item>
                    }
                    <Form.Item name="sort" label="排序序号"  rules={[{ required: false, message: '请输入类型名称!' }]}>
                        <Input autoComplete="off" defaultValue={ 0} placeholder='请输入序号!' type='number' />
                    </Form.Item>
                  
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}