import React, { useId } from 'react';
import { Button, Pagination, Modal, message, Select, Image } from 'antd'
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import moment from 'moment';
import CustomModal from '../../common/Modal';
import SelectList from '../../common/SelectList';
import InputModal from './InputModal';
export default class InterviewLog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
            job_type_id:0,//职务类型ID
			name: "",
            status:-1,
            job_id:0,
            tips:"",
            editStatus:0
		}
		this.columns = [
			{
				title: '序号',
				width: 80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: 'HR信息',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.hr_avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.hr_name}</p>
								<p>{info.hr_job_name}·{info.hr_mobile}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '面试人信息',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.user_avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.user_name}</p>
								<p>{info.user_mobile}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '企业信息',
				dataIndex: 'user_type',
				key: 'user_type',
				align: 'left',
				
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.company_logo} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.company_name}</p>
								<p>{info.team_name}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '职务信息',
				dataIndex: 'user_type',
				key: 'user_type',
				align: 'center',
				
				className: 'pubname',
				render: (_, info) => {
					return (
						<>
                        <p>{info.job_type_name}</p>
                        <p>{info.wage_min}k-{info.wage_max}k{info.wage_month>12?("·"+info.wage_month):""}</p>
                        </>
					);
				}
			}, {
				title: '面试信息',
				dataIndex: 'city_info',
				key: 'city_info',
				align: 'left',
                render:(_,info)=>{
                    return(
                        <>
                        <p>时间:{info.interview_date} {info.interview_time}</p>
                        <p>地点:{info.interview_address}{info.interview_address_detail}</p>
                        </>
                    );
                }
			}, {
				title: '状态',
				dataIndex: 'status',
				key: 'status',
				align: 'center',
                render:(status)=>{
                    if(status==0){
                        return <p className="color3">待接受</p>;
                    }else if(status==1){
                        return <p className="color4">已拒绝</p>;
                    }else if(status==2){
                        return <p className="color1">待面试</p>;
                    }else if(status==3){
                        return <p className="color2">面试结束</p>;
                    }
                }
			}, {
				title: '审核备注',
				dataIndex: 'remarks',
				key: 'remarks',
				align: 'center',
				render: (remarks,info) => {
                    if(info.status!=3){
                        return "-";
                    }
                    if(info.feedback_status==0){
                        return "待反馈";
                    }else if(info.feedback_status==1){
                        return<>
                        <p className='color1'>面试通过</p>
                        <p className='color1'>{info.feedback_remarks}</p>
                        </>
                    }else if(info.feedback_status==2){
                        return<>
                        <p className='color3'>不合适</p>
                        <p className='color3'>{info.feedback_remarks}</p>
                        </>
                    }else if(info.feedback_status==3){
                        return<>
                        <p className='color4'>考虑中</p>
                        <p className='color4'>{info.feedback_remarks}</p>
                        </>
                    }

                }
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy: info.orderBy,
			status: this.state.status,
            job_type_id:this.state.job_type_id
		}
		global.Ajax.hhtc_request('job/interviewLog', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(job_id,editStatus,tips) {
		this.setState({
			job_id,editStatus,tips,visible:true
		})
	}
	
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入团队名称、公司名称、HR名称查询'
						className='borderbai'
						onChange={(e) => {

							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
					<SelectList
						type="jobTypeList"
						className='marginr12 borderbai'
                        placeholder="请选择职务类型"
						style={{ width: 160 }}
						onChange={(role_id) => {
							this.setState({
								job_type_id:role_id||0,
							}, () => {
								this.refresh()
							})
						}}
					/>
                    
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>面试记录列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'

					/>
				</div>
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={this.state.tips}
					onCancel={this.onCancel.bind(this)}
				>
					<InputModal job_id={this.state.job_id} status={this.state.editStatus} reqUrl="job/enableJob" onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)}/>
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
            status:1,
            job_id:0
		})
	}
}
