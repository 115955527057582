import React from 'react';
import { Button, Input, message, Form } from 'antd'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPwd: "",
            password: "",
            password1: "",
            loading: false
        }
    }
    onOk() {
        if (this.state.oldPwd.length < 6 || this.state.oldPwd.length > 16) {
            return message.error("原密码格式错误")
        }
        if (this.state.password.length < 6 || this.state.password.length > 16) {
            return message.error("新密码格式错误")
        }
        if (this.state.password != this.state.password1) {
            return message.error("两次密码输入不一致")
        }
        this.setState({
            loading: true
        }, () => {
            var req = {
                oldPwd: this.state.oldPwd,
                password: this.state.password
            }
            global.Ajax.hhtc_request("admin/editPwd", req).then(data => {
                if (data.code === 1) {
                    // this.setState({
                    //     systemName:data.data.name,
                    //     username:data.data.username,
                    //     avatar:data.data.avatar
                    // })
                    // this.props.onCancel()
                    message.success('修改成功，请重新登录！', 1.2, () => {
                        window.location.href = ''
                    })
                } else {
                    message.error(data.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false
                    })
                }, 1500);
            })
        })
    }
    checkPwd = (val) => {
        console.log(val)
        if (this.inpt1.target.value != this.inpt2.target.value) {
            return Promise.reject(new Error('两次密码输入不一致!'));
        }
        return Promise.resolve();
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            global.Ajax.hhtc_request("admin/editPwd", req).then(data => {
                if (data.code === 1) {
                    message.success('修改成功，请重新登录！', 1.2, () => {
                        window.location.href = ''
                    })
                } else {
                    message.error(data.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false
                    })
                }, 1500);
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{ span: 6 }}
                >
                    <Form.Item name='oldPwd' label="原密码：" rules={[{ required: true, message: '请输入6-16位原密码!' }]}>
                        <Input autoComplete="off" placeholder='请输入6-16位原密码' type='password' />
                    </Form.Item>
                    <Form.Item name='password' label="新密码：" rules={[{ required: true, message: '请设置6-16位新密码!' }, ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || (value.length >= 6 && value.length <= 16)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('请设置6-16位新密码!'));
                        }
                    })]}>
                        <Input ref={dom => this.inpt1 = dom} autoComplete="off" placeholder='请输入6-16位原密码' type='password' />
                    </Form.Item>
                    <Form.Item name='pwd1' label="确认密码：" rules={[{ required: true, message: '两次密码不一致！' }, ({ getFieldValue }) => ({
                        validator(_, value) {
                            console.log(value)
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('两次密码不一致!'));
                        }
                    })]}>
                        <Input autoComplete="off" placeholder='请再次输入新密码' type='password' />
                    </Form.Item>
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}
