import React from 'react';
import { Layout, Menu, Breadcrumb, Button, Dropdown, Modal, message, Tabs } from 'antd';
import {
    Route,
    Switch,
    HashRouter as Router,
    Redirect,
} from 'react-router-dom';

import RoleList from './Basic/RoleList';  //角色列表
import AdminList from './Basic/AdminList';  //管理员列表
import OperationLog from './Basic/OperationLog';  //操作日志
import MenuSet from './Set/MenuSet';  //菜单设置
import UploadSet from './Set/UploadSet';  //上传设置
import BasicInfo from './Set/BasicInfo';  //基本信息设置配置

import SetColor from './SetColor';  //主题
import EditPwd from './EditPwd';  //修改密码
import UserInfo from './UserInfo';  //个人信息

import CustomModal from '../common/Modal';

//配置类页面
import CompanyType from './config/CompanyType';
import FinanceType from './config/FinanceType';
import StaffSize from './config/StaffSize';
import JobType from './config/JobType';
import SkillType from './config/SkillType';
import SkillLevel from './config/SkillLevel';
import FeedbackType from './config/FeedbackType';
import ComplaintType from './config/ComplaintType';
import School from './config/School';
import Major from './config/Major';
import CFAType from './config/CFAType';
import MajorAreas from './config/MajorAreas';
import User from './user/User';
import UserResumeFile from './user/UserResumeFile';
import UserRechargeLog from './user/UserRechargeLog';
import CompanyList from './company/CompanyList';
import CompanyTeam from './company/CompanyTeam';
import CompanyUser from './company/CompanyUser';

import JobList from './job/JobList';
import InterviewLog from './job/InterviewLog';
import Complain from './job/Complain';

import Post from './job/Post';
import FeedBack from './job/FeedBack';
import CompanyXz from './config/CompanyXz'
import UserVip from './user/UserVip';  // 用户VIP配置
import ResumeTempList from './user/ResumeTempList';  // 简历模板配置
import HrVipConfig from './company/HrVipConfig';  // hr VIP配置
import PostConfig from './config/PostConfig';  // 职务配置
import Trade from './config/Trade';  // 行业配置
import Degree from './config/Degree';  // 学位管理
import HrApplyList from './company/HrApplyList';  // hr申请记录

// --------------------------
import Banner from './config/Banner';  //轮播管理

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const Components = {
    'Rolelist': RoleList,
    "Adminlist": AdminList,
    "Operationlog": OperationLog,
    "Menuset": MenuSet,
    "Uploadset": UploadSet,
    "Basicinfo": BasicInfo,
    "CompanyType":CompanyType,
    "FinanceType":FinanceType,
    "StaffSize":StaffSize,
    "JobType":JobType,
    "SkillType":SkillType,
    "SkillLevel":SkillLevel,
    "FeedbackType":FeedbackType,
    "ComplaintType":ComplaintType,
    "School":School,
    "Major":Major,
    "CFAType":CFAType,
    "User":User,
    "MajorAreas":MajorAreas,
    "UserResumeFile":UserResumeFile,
    "UserRechargeLog":UserRechargeLog,
    "CompanyList":CompanyList,
    "CompanyTeam":CompanyTeam,
    "CompanyUser":CompanyUser,
    "JobList":JobList,
    "InterviewLog":InterviewLog,
    "Complain":Complain,
    "Post":Post,
    "FeedBack":FeedBack,
    "Banner": Banner,
    "CompanyXz": CompanyXz,
    "UserVip": UserVip,
    "ResumeTempList": ResumeTempList,
    "HrVipConfig": HrVipConfig,
    "PostConfig": PostConfig,
    "Trade": Trade,
    "Degree": Degree,
    "HrApplyList": HrApplyList
}

export default class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            menu: [],
            headerIcon: 'icon-jibenguanli',
            headerFirstName: '基本管理',
            headerSecName: '角色列表',
            visible: false,
            pwdVisible: false,
            infoVisible: false,
            systemName: "鸿鹄科技管理后台",
            username: "",
            avatar: "",
            selectKey: '',
            openKey: '',
            reqFinished: false,
            tabs: []
        }
        this.tabRef = [];
    }
    componentDidMount() {
        this.getUserInfo();
        setTimeout(() => {
            window.delDom()
        }, 2000);
    }
    toggle = () => {
        var zancOpenKey = '', openKey = '';
        let collapsed = !this.state.collapsed;
        if (collapsed) {  // 
            zancOpenKey = this.state.openKey;
            openKey = ''
        } else {
            openKey = this.state.zancOpenKey;
            zancOpenKey = ''
        }
        this.setState({
            collapsed: !this.state.collapsed,
            openKey,
            zancOpenKey,
        });
    };
    changeVisible(key) {
        this.setState({
            [key]: true,
        })
    }
    getUserInfo() {
        global.Ajax.hhtc_request("admin/getLoginInfo", {}).then(data => {
            if (data.code === 1) {
                document.title = data.data.name;
                var menu = data.data.menus;
               
                // let menu = [];
                // menu = menu.concat([{ id: 26, path: 'adminlist', title: '测试菜单', icon: 'icon-yonghu', child: [] }])
                var tabs = [], activeKey = '', selectKey, openKey;
                if (menu.length > 0) {
                    var path;
                    if (menu[0].child.length > 0 && menu[0].path == '') {
                        path = (menu[0].child[0].path.split('/'))[menu[0].child[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].child[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].child[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].child[0].id
                        }]
                    } else {
                        path = (menu[0].path.split('/'))[menu[0].path.split('/').length - 1];
                        tabs = [{
                            title: menu[0].title,
                            component: path.substr(0, 1).toUpperCase() + path.substr(1),
                            key: 'tab' + menu[0].id,
                            subKey: 'sub' + menu[0].id,
                            menuKey: menu[0].id,
                        }]
                    }
                    
                    activeKey = tabs[0].key;
                    selectKey = tabs[0].menuKey;
                    openKey = tabs[0].subKey;
                }
                
                this.setState({
                    systemName: data.data.name,
                    username: data.data.username,
                    avatar: data.data.avatar,
                    menu,
                    tabs,
                    activeKey,
                    selectKey,
                    openKey,
                    reqFinished: true,
                })
            }
        })
    }
    handMenu(e) {
        let openKey = this.state.openKey,
            zancOpenKey = this.state.openKey;
        if (this.state.collapsed || e.keyPath.length == 1) {
            openKey = '';
        }
        this.setState({
            selectKey: e.key,
            zancOpenKey,
            openKey,
        })
    }
    handTitle(e) {
        let openKey = ''
        if (this.state.openKey != e.key) {
            openKey = e.key;
        }
        this.setState({
            openKey
        })
    }
    // 刷新
    onRefresh = () => {
        // console.log(this.tabRef[this.state.selectKey].state)
        this.tabRef[this.state.selectKey].refresh()
    }
    render() {
        const { collapsed } = this.state;
        const menu = (
            <Menu>
                <Menu.Item key="1" onClick={this.changeVisible.bind(this, 'pwdVisible')}>修改密码</Menu.Item>
                <Menu.Item key="2" onClick={this.changeVisible.bind(this, 'infoVisible')}>个人信息</Menu.Item>
                <Menu.Item key="3" onClick={() => {
                    window.reset()
                    localStorage.removeItem('honghuToken')
                    message.success("再见", 1, () => {
                        window.location.href = ''
                    })
                }}>退出登录</Menu.Item>
            </Menu>
        )
        const list = (path, id) => {
            var MyComponentt = Components[path];
            return <MyComponentt ref={dom => this.tabRef[id] = dom} />;
        }
        return (
            <Layout style={{ minHeight: '100vh', _height: '100vh' }}>
                <Sider trigger={null} theme='light' width={240} collapsible collapsed={collapsed} onCollapse={this.toggle} className='leftMenu'>
                    <div className="logo">
                        <p>{this.state.systemName.substr(0, 2)}</p>
                        <h1>{this.state.systemName}</h1>
                    </div>
                    {this.state.reqFinished && <Menu openKeys={[this.state.openKey]} selectedKeys={[String(this.state.selectKey)]} mode="inline" onClick={this.handMenu.bind(this)}>
                        {this.state.menu.map((item, index) => (
                            <React.Fragment key={'' + index}>
                                {(item.child.length > 0 && item.path == '') ?
                                    <SubMenu key={`sub${item.id}`} icon={<p className={`iconfont ${item.icon}`}></p>} title={item.title} onTitleClick={this.handTitle.bind(this)}>
                                        {item.child.map((row, k) => (
                                            <Menu.Item key={String(row.id)} onClick={this.add.bind(this, row, item)}>{row.title}</Menu.Item>
                                        ))}
                                    </SubMenu>
                                    :
                                    <Menu.Item className='firr' key={String(item.id)} icon={<p className={`iconfont ${item.icon}`}></p>} onClick={this.add.bind(this, item, item)}>{item.title}</Menu.Item>
                                }
                            </React.Fragment>
                        ))}
                    </Menu>}
                </Sider>
                <Layout className="site-layout">
                    <Header>
                        <div className='topnav'>
                            <p className={`iconfont ${this.state.collapsed ? 'icon-zhankai' : 'icon-shouqi'} leftMenuToggle`} onClick={this.toggle.bind(this)}></p>
                            <p className={`iconfont icon-shuaxin leftMenuToggle`} onClick={this.onRefresh}></p>
                            <div className='zhut flexCenter' onClick={this.changeVisible.bind(this, 'visible')}>
                                <p className='iconfont icon-zhuti'></p>
                                <p>主题</p>
                            </div>
                            {(this.state.avatar == "" || !this.state.avatar) ?
                                <img alt='' src={require('../imgs/default.png').default} className='avatar' />
                                :
                                <img alt='' src={this.state.avatar} className='avatar' />
                            }
                            <Dropdown placement='bottomCenter' overlay={menu}>
                                <div className='flexCenter'>
                                    <p>{this.state.username}</p>
                                    <span className='iconfont icon-jiantou-shang'></span>
                                </div>
                            </Dropdown>
                        </div>
                    </Header>
                    <Content>
                        {this.state.reqFinished && <Tabs
                            type="editable-card"
                            hideAdd={true}
                            activeKey={this.state.activeKey || 'tab0'}
                            className='asdTabs'
                            onChange={this.onChange}
                            onEdit={this.onEdit}
                        >
                            {this.state.tabs.map(item => (
                                <TabPane closable={this.state.tabs.length == 1 ? false : true} closeIcon={(<p className='iconfont icon-guanbi closeTab'></p>)} tab={item.title} key={item.key}>
                                    {list(item.component, item.menuKey)}
                                </TabPane>
                            ))}
                        </Tabs>}
                        {/* <Router>
                            <Switch>
                                <Route path="/rout/rolelist" component={RoleList} />
                                <Route path="/rout/adminlist" component={AdminList} />
                                <Route path="/rout/operationlog" component={OperationLog} />
                                <Route path="/rout/menuset" component={MenuSet} />
                                <Route path="/rout/uploadset" component={UploadSet} />
                                <Route path="/rout/basicinfo" component={BasicInfo} />
                            </Switch>
                        </Router> */}
                    </Content>
                </Layout>
                {/* 修改主题 */}
                <CustomModal
                    visible={this.state.visible}
                    width={1172}
                    title='主题配色'
                    onCancel={this.onCancel.bind(this)}
                >
                    <SetColor ref={dom => this.setcolor = dom} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
                </CustomModal>
                {/* 修改密码 */}
                <CustomModal
                    visible={this.state.pwdVisible}
                    width={360}
                    title='修改密码'
                    onCancel={this.onCancel.bind(this)}
                >
                    <EditPwd ref={dom => this.editpwd = dom} onCancel={this.onCancel.bind(this)} />
                </CustomModal>
                {/* 个人信息 */}
                <CustomModal
                    visible={this.state.infoVisible}
                    width={360}
                    title='个人信息'
                    onCancel={this.onCancel.bind(this)}
                >
                    <UserInfo
                        ref={dom => this.userinfo = dom}
                        avatar={this.state.avatar}
                        username={this.state.username}
                        onCancel={this.onCancel.bind(this)}
                        onRefresh={this.getUserInfo.bind(this)}
                    />
                </CustomModal>
            </Layout>
        )
    }
    add(row, itemm) {
        // console.log(row)
        var { tabs } = this.state;
        var rowItem = tabs.find(item => item.title == row.title);
        if (!rowItem) {
            var path = (row.path.split('/'))[row.path.split('/').length - 1];
            var obj = {
                title: row.title,
                component: path.substr(0, 1).toUpperCase() + path.substr(1),
                key: 'tab' + row.id,
                subKey: 'sub' + itemm.id,
                menuKey: row.id,
            }
            tabs.push(obj);
            this.setState({
                tabs,
                activeKey: obj.key
            })
        } else {
            this.setState({
                activeKey: rowItem.key
            })
        }
        if (row.path != '') {
            // this.props.history.push(row.path)
            this.setState({
                headerIcon: itemm.icon,
                headerFirstName: itemm.title,
                headerSecName: row.title
            })
        }
    }
    onEdit = (targetKey, action) => {
        // console.log(action, targetKey)
        this[action](targetKey)
    }
    remove = targetKey => {
        const { tabs, activeKey } = this.state;
        let newActiveKey = activeKey, selectKey = '', openKey = '';
        let lastIndex;
        tabs.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = tabs.filter(pane => pane.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
                selectKey = newPanes[lastIndex].menuKey;
                openKey = newPanes[lastIndex].subKey;
            } else {
                newActiveKey = newPanes[0].key;
            }
        } else {
            newActiveKey = newPanes[newPanes.length - 1].key;
            selectKey = newPanes[newPanes.length - 1].menuKey;
            openKey = newPanes[newPanes.length - 1].subKey;
        }
        let zancOpenKey = openKey;
        if (!this.state.collapsed) {
            // openKey=openKey;
        } else {
            openKey = '';
        }
        this.setState({
            tabs: newPanes,
            activeKey: newActiveKey,
            openKey,
            selectKey,
            zancOpenKey
        });
    }
    onChange = (activeKey) => {
        const { tabs } = this.state;
        var row = tabs.find(item => item.key == activeKey);
        let openKey = '',
            zancOpenKey = row.subKey;
        if (!this.state.collapsed) {
            openKey = row.subKey;
        }
        this.setState({
            activeKey,
            selectKey: row.menuKey,
            openKey,
            zancOpenKey
        })
    }
    onOk() {
        this.onCancel()
    }
    onCancel() {
        this.setState({
            visible: false,
            pwdVisible: false,
            infoVisible: false,
        })
    }
}
