import React, { useState } from 'react';
import { Button, Input, Image, Switch, Form, message,Select } from 'antd'
import SelectList from '../../common/SelectList';
import UploadList from '../../common/UploadList';
// import Ueditor from '../../common/Ueditor';
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import MyEditor from '../../common/MyEditor';

function ImageDemo(props) {
    const [visible, setVisible] = useState(false);
    return (
        <React.Fragment>
            <img alt='' src={props.url} />
            <div className='ylmask'>
                <span className='yl' onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setVisible(true)
                }}>预览</span>
            </div>
            <Image
                src={props.url}
                style={{ display: 'none' }}
                preview={{
                    visible,
                    src: props.url,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    }
                }}
            />
        </React.Fragment>
    )
}

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            typeList: [
                { value: 1, label: '文本' },
                { value: 2, label: '数字' },
                { value: 3, label: '图片' },
                { value: 4, label: '图文' },
                { value: 5, label: '开/关' },
            ],
            value: props.data.value || '',
            title: props.data.title || undefined,
            valueType: props.data.type || 1,
            canDel: props.data.canDel == 1 ? true : false,
            helpInfo: ''
        }
        console.log(props);
    }
    componentDidMount() {
        let data=this.props.data;
        this.formTable.setFieldsValue({
            value: data.value,
            title: data.title,
            type: data.type,
        })
        if (this.props.data.type == 4) {
            this.ueditor.setContent(this.props.data.value)
        }
    }
    changeValue(value) {
        this.setState({
            valueType: value,
            value: ''
        })
    }
    checkValue = () => {
        var value = this.state.value;
        if (this.state.valueType == 4) {
            value = this.ueditor.getContent()
        }
        if (value == '' && this.state.valueType != 5) {
            return Promise.reject(new Error('配置值不能为空!'));
        }
        return Promise.resolve();
    }
    // 添加配置
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            if (req.type == 5) {
                req.value = req.value ? 1 : 0;
            }
            var url = 'setting/addSetting';
            if (this.props.type == 'edit') {
                url = 'setting/editSetting';
                req = {
                    id: this.props.data.id,
                    title: req.title,
                    type: req.type,
                    value: req.value
                }
            } else {
                req.canDel = req.canDel ? 1 : 0;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.props.onOk()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox basicBox' style={{ minHeight: 484, display: 'flex', flexDirection: 'column' }}>
                <Form
                    onFinish={this.onFinish}
                    initialValues={{
                        type: this.props.data.type || 1,
                        canDel: this.props.data.canDel == 1 ? true : false,
                        title: this.props.data.title || undefined,
                        value: undefined
                    }}
                    ref={dom => this.formTable = dom}
                    onValuesChange={(res) => {
                        let key = (Object.keys(res))[0];
                        if (key == 'type') {
                            this.formTable.setFieldsValue({
                                value: undefined,
                            })
                        }
                    }}
                >
                    <div className='flexWrap'>
                        <Form.Item name="title" label="配置名称：" rules={[{ required: true, message: '请输入配置名称!' }]}>
                            <Input autoComplete="off" placeholder='请输入配置名称' type='text' />
                        </Form.Item>
                        <Form.Item name="type" label="值类型：" rules={[{ required: true, message: '请选择配置类型!' }]}>
                            <Select options={this.state.typeList} placeholder='请选择配置类型'></Select>
                            {/* <SelectList
                                placeholder='请选择配置类型'
                                data={this.state.typeList}
                                value={this.state.valueType}
                                style={{ flex: 1 }}
                                onChange={this.changeValue.bind(this)}
                            /> */}
                        </Form.Item>
                        <Form.Item name="canDel" label="允许删除：" valuePropName="checked">
                            <Switch checkedChildren="是" unCheckedChildren="否" disabled={this.props.type == 'edit' ? true : false} />
                        </Form.Item>
                    </div>
                    <Form.Item noStyle shouldUpdate={(prev, cur) => prev.type != cur.type}>
                        {({ getFieldValue }) => (
                            <Form.Item label='配置值' name="value" rules={[{ required: true, message: '配置值不能为空！' }]}>
                                {getFieldValue('type') == 1 ? <Input.TextArea rows={8} className='txtarea' placeholder='请输入内容' /> : (
                                    getFieldValue('type') == 2 ? <Input autoComplete='off' placeholder='请输入' /> : (
                                        getFieldValue('type') == 3 ? <React.Fragment>
                                            <Input bordered={false} style={{ display: 'none' }} />
                                            {this.state.value == '' && <div className='upbox' onClick={() => {
                                                this.uplist.setState({
                                                    visible: true,
                                                })
                                            }}>
                                                <span className='iconfont icon-xiangji'></span>
                                                <span className='zi'>选择图片</span>
                                            </div>}
                                            {this.state.value != '' && <div className='picboxx' onClick={() => {
                                                this.uplist.setState({
                                                    visible: true,
                                                })
                                            }}>
                                                <ImageDemo url={this.state.value} />
                                            </div>}
                                        </React.Fragment> : (
                                            getFieldValue('type') == 4 ? <React.Fragment>
                                                <Input style={{ display: 'none' }} />
                                                <MyEditor
                                                    ref={e => this.ueditor = e}
                                                    className="myarea"
                                                    style={{ height: 200, flex: 1, }}
                                                    changeValue={(e) => {
                                                        this.formTable && this.formTable.setFieldsValue({
                                                            value: e,
                                                        })
                                                    }}
                                                />
                                            </React.Fragment> : <Switch checkedChildren="开" unCheckedChildren="关" />
                                        )
                                    )
                                )}
                            </Form.Item>
                        )}
                    </Form.Item>
                    {/* <Form.Item label="配置值：" name='value' validateStatus={this.state.helpInfo != '' ? 'error' : ''} help={this.state.helpInfo}>
                        {this.state.valueType == 1 && <Input.TextArea rows={8} className='txtarea' placeholder='请输入内容' value={this.state.value} onChange={(e) => {
                            this.setState({
                                value: e.target.value
                            })
                        }} />}
                        {this.state.valueType == 2 && <Input placeholder='请输入' value={this.state.value} onChange={(e) => {
                            this.setState({
                                value: e.target.value
                            })
                        }} />}
                        {this.state.valueType == 3 && <React.Fragment>
                            {this.state.value == '' && <div className='upbox' onClick={() => {
                                this.uplist.setState({
                                    visible: true,
                                })
                            }}>
                                <span className='iconfont icon-xiangji'></span>
                                <span className='zi'>选择图片</span>
                            </div>}
                            {this.state.value != '' && <div className='picboxx' onClick={() => {
                                this.uplist.setState({
                                    visible: true,
                                })
                            }}>
                                <ImageDemo url={this.state.value} />
                            </div>}
                        </React.Fragment>}
                        {this.state.valueType == 4 && <React.Fragment>
                            <Input style={{ display: 'none' }} />
                            <MyEditor
                                ref={e => this.ueditor = e}
                                className="myarea"
                                style={{ height: 200, flex: 1, }}
                                changeValue={(e) => {
                                    this.formTable && this.formTable.setFieldsValue({
                                        value: e,
                                    })
                                }}
                            />
                        </React.Fragment>}
                        {this.state.valueType == 5 && <Switch checked={this.state.value == 1 ? true : false} checkedChildren="开" unCheckedChildren="关" onChange={(value) => {
                            this.setState({ value })
                        }} />}
                    </Form.Item> */}
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
                {/* 文件库 */}
                <UploadList fileNum={1} ref={dom => this.uplist = dom} type={1} onOk={this.onUploadOk.bind(this)} />
            </div>
        );
    }
    onUploadOk(data) {
        this.setState({
            value: data[0]
        })
        this.formTable.setFieldsValue({
            value: data[0],
        })
        this.uplist.onCancel()
    }
}