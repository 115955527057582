import React from 'react';
import { Button, Modal, Form, Input } from 'antd'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
        }
    }
    onFinish = (req) => {
        this.props.onFinish(req)
    }
    render() {
        const { status } = this.state;
        return (
            <Modal
                visible={this.state.visible}
                centered={true}
                destroyOnClose={true}
                footer={null}
                closeIcon={(<p className='iconfont icon-guanbi'></p>)}
                title={`填写备注`}
                width={360}
                onCancel={this.onCancel.bind(this)}
            >
                <Form
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        name='remarks'
                        rules={[{ required: true, message: `请填写备注！` }]}
                        initialValue={this.props.remarks}
                    >
                        <Input.TextArea rows={8} readOnly={this.props.type == 'readOnly' ? true : false} placeholder={`请填写备注`} className='height200' />
                    </Form.Item>
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </Modal>
        )
    }
    onCancel() {
        this.setState({
            visible: false,
            status: null,
        }, () => {
            this.props.onCancel()
        })
    }
}
