import { Button, Image } from 'antd';
import React from 'react';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: props.type,
            companyInfo: {},
            teamInfo: {},
            userInfo: props.data,
        }
    }
    componentDidMount() {
        console.log(this.props);
        if (this.props.data.company_id == 0) {
            let data = JSON.parse(JSON.stringify(this.props.data));
            data.team_logo = data.team_logo == '' ? [] : data.team_logo.split(',');
            this.setState({
                companyInfo: this.props.data,
                teamInfo: data,
            })
        } else {
            this.getInfo()
        }
    }
    getInfo() {
        if (this.props.data.company_id == 0) {
            return;
        }
        if (this.state.type == 'team') {
            this.getTeamInfo()
        } else if (this.state.type == 'business') {
            this.getCompanyInfo()
        }
    }
    getCompanyInfo() {
        global.Ajax.hhtc_request('common/getCompanyInfo', { company_id: this.props.data.company_id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    companyInfo: res.data,
                })
            }
        })
    }
    getTeamInfo() {
        global.Ajax.hhtc_request('common/getTeamInfo', { team_id: this.props.data.team_id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    teamInfo: res.data,
                })
            }
        })
    }
    render() {
        const { type, companyInfo, teamInfo, userInfo } = this.state;
        return (
            <div style={{ position: 'relative', paddingTop: 1, }}>
                <div className='wrapbox flexColumn'>
                    {/* 返回 */}
                    <div className='headd flex' style={{ position: 'absolute', left: 15, top: 0, right: 0, zIndex: 99, paddingTop: 20, paddingBottom: 6, background: '#fff' }}>
                        <p style={{ marginLeft: -10, width: 24, height: 24, }} onClick={() => {
                            if (type == 'hr') {
                                return
                            }
                            this.setState({
                                type: 'hr'
                            })
                        }}>
                            {(type != 'hr' && this.props.type == 'hr') && <img src={require('../../imgs/back.png').default} style={{ display: 'block', width: 24, }} />}
                        </p>
                        <span style={{ fontSize: 14, flex: 1, textAlign: 'center', marginRight: 28, }}>详情</span>
                    </div>
                    <div className='boxx ovauu' style={{ minHeight: '100%', paddingBottom: 30, position: 'relative', paddingTop: 54, }}>
                        {/* hr个人信息 */}
                        {type == 'hr' && <React.Fragment>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>头像</p>
                                <div className='avatar' style={{ width: 44, height: 44, }}>
                                    <Image
                                        width={44}
                                        height={44}
                                        src={userInfo.avatar}
                                    />
                                </div>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>姓名</p>
                                <p className='p3'>{userInfo.name}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>手机号</p>
                                <p className='p3'>{userInfo.mobile}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>微信号</p>
                                <p className='p3'>{userInfo.wx_no || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>邮箱</p>
                                <p className='p3'>{userInfo.email || ''}</p>
                            </div>
                            <div className='flexCenter borderbot1 pubname'>
                                <div className='p3' style={{ flex: 1 }}>我的公司</div>
                                <p className='p3' onClick={() => {
                                    this.setState({
                                        type: 'business'
                                    }, () => {
                                        this.getInfo()
                                    })
                                }}>{userInfo.company_name || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1 pubname'>
                                <div className='p3' style={{ flex: 1 }}>我的团队</div>
                                <p className='p3' onClick={() => {
                                    this.setState({
                                        type: 'team'
                                    }, () => {
                                        this.getInfo()
                                    })
                                }}>{userInfo.team_name || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>我的职务</p>
                                <p className='p3'>{userInfo.job_name}</p>
                            </div>
                        </React.Fragment>}
                        {/* 企业信息 */}
                        {type == 'business' && <React.Fragment>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>营业执照</p>
                                <div style={{ width: 70, height: 42, }}>
                                    <Image
                                        width={70}
                                        height={42}
                                        src={companyInfo.business_license}
                                    />
                                </div>
                            </div>
                            <div className='flexCenter borderbot1' style={{ alignItems: 'flex-start' }}>
                                <p className='p3'>企业名称</p>
                                <p className='p3' style={{ maxWidth: 180, marginLeft: 'auto', }}>{companyInfo.company_name || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>法人代表</p>
                                <p className='p3'>{companyInfo.legal || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>注册资本</p>
                                <p className='p3'>{companyInfo.capital || '-'}万元</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>成立日期</p>
                                <p className='p3'>{companyInfo.company_reg_date || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1' style={{ alignItems: 'flex-start' }}>
                                <p className='p3'>统一信用代码</p>
                                <p className='p3' style={{ maxWidth: 140, marginLeft: 'auto', }}>{companyInfo.credit || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>企业地址</p>
                                <p className='p3'>{companyInfo.company_address || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>企业LOGO</p>
                                <div className='avatar' style={{ width: 44, height: 44, }}>
                                    <Image
                                        width={44}
                                        height={44}
                                        src={companyInfo.company_logo}
                                    />
                                </div>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>融资阶段</p>
                                <p className='p3'>{companyInfo.finance || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>人员规模</p>
                                <p className='p3'>{companyInfo.scale || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>事务所类型</p>
                                <p className='p3'>{companyInfo.company_type || '-'}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>事务所性质</p>
                                <p className='p3'>{companyInfo.xz || '-'}</p>
                            </div>
                            <div className='flexCenter'>
                                <p className='p3' style={{ flex: 1 }}>企业简介</p>
                            </div>
                            <div className='borderbot1'>
                                <p className='p1' style={{ marginTop: 10 }}>{companyInfo.company_introduce || '-'}</p>
                            </div>
                        </React.Fragment>}
                        {/* 团队信息 */}
                        {type == 'team' && <React.Fragment>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>团队名称</p>
                                <p className='p3'>{teamInfo.team_name || '-'}</p>
                            </div>
                            {/* <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>团队所在地区</p>
                                <p className='p3'>{teamInfo.province}{teamInfo.city}{teamInfo.area}</p>
                            </div>
                            <div className='flexCenter borderbot1'>
                                <p className='p3' style={{ flex: 1 }}>详细地址</p>
                                <p className='p3'>{teamInfo.address || ''}</p>
                            </div> */}
                            <div className='flexCenter'>
                                <p className='p3' style={{ flex: 1 }}>团队简介</p>
                            </div>
                            <div className='borderbot1'>
                                <p className='p1' style={{ marginTop: 10 }}>{teamInfo.team_desc || '-'}</p>
                            </div>
                            <div className='flexCenter'>
                                <p className='p3' style={{ flex: 1 }}>团队照片</p>
                            </div>
                            <div className='imgbox' style={{ marginTop: 10 }}>
                                <Image.PreviewGroup>
                                    {teamInfo.team_logo && teamInfo.team_logo.map((item, index) => (
                                        <Image
                                            width={70}
                                            height={70}
                                            src={item}
                                            key={String(index)}
                                        />
                                    ))}
                                </Image.PreviewGroup>
                            </div>
                        </React.Fragment>}
                    </div>
                    {/* {this.props.all && <Button type='primary' onClick={() => {
                        if (type == 'hr') {
                            this.props.onClose && this.props.onClose();
                        } else {
                            this.setState({
                                type: type == 'business' ? 'team' : 'hr',
                            }, () => {
                                this.getInfo()
                            })
                        }
                    }} style={{ position: 'absolute', left: 0, right: 0, bottom: 0, }}>{type == 'hr' ? '关闭' : '下一步'}</Button>} */}
                </div>
                <img alt='' src={require('../../imgs/shouj.png').default} className='boximg' />
            </div>
        )
    }
}
