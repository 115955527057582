import React from 'react';
import { Button, Tooltip, Pagination, Modal, Empty, Spin } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import TabbleList from '../../common/TabbleList';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';
export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			orderBy: '',
			admin_id: '',
			address: '',
			ip: '',
			desc: '',
		}
		this.columns = [
			{
				title: '序号',sorter: true,
				dataIndex: 'key',
				key: 'key',
				align: 'center'
			}, {
				title: '操作人',
				dataIndex: 'username',
				key: 'username',
				align: 'center'
			}, {
				title: '操作内容',
				dataIndex: 'desc',
				key: 'desc',
				align: 'center'
			}, {
				title: '操作IP',
				dataIndex: 'ip',
				key: 'ip',
				align: 'center'
			}, {
				title: '操作地址',
				dataIndex: 'address',
				key: 'address',
				align: 'center'
			}, {
				title: '操作时间',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center'
			}
		]
	}
	refresh(){
		this.tabblelist.onRefresh()
	}
	getList(info,callback) {
		var orderBy = '';
		if (this.state.orderBy != '') {
			orderBy =  this.state.orderBy;
		}
		var req = {
			page: info.page,
			size: info.size,
			admin_id: this.state.admin_id,
			address: this.state.address,
			ip: this.state.ip,
			desc: this.state.desc,
			orderBy:info.orderBy
		}
		global.Ajax.hhtc_request('admin/adminLog', req).then(res => {
			callback(res)
		})
	}
	
	// 搜索
	search(name, key) {
		this.setState({
			[key]: name,
			page: 1,
			data: []
		}, () => {
			this.refresh()
		})
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入操作内容关键词'
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value, 'desc')
						}}
						
					/>
					<SelectList
						type="alladmin"
						placeholder='请选择操作人'
						className='marginr12 borderbai'
						style={{ width: 160 }}
						onChange={(admin_id) => {
							this.setState({
								admin_id,
								page: 1,
								data: []
							}, () => {
								this.refresh()
							})
						}}
					/>
					<Input
						placeholder='请输入操作地址'
						prefix={null}
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value, 'address')
						}}
						
					/>
					<Input
						placeholder='请输入操作IP'
						prefix={null}
						className='borderbai'
						onChange={(e) => {
							this.search(e.target.value, 'ip')
						}}
						
					/>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>操作日志列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
						
					/>
				</div>
			</React.Fragment>
		)
	}
}
