import React from 'react';
import { Button, Pagination, Modal, message, Select, Image } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import CustomModal from '../../common/Modal';
import BusinessInfo from './BusinessInfo';  // 企业信息
import HRInfo from './HRInfo';  // hr信息

export default class CompanyList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			company_type:0,
            scale_id:0,
            name:"",
			editData:{},
		}
		this.columns = [
			{
				title: '序号',
                width:80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '企业logo',
				dataIndex: 'company_logo',
				key: 'company_logo',
				align: 'center',
				width:100,
				render:(avatar)=>(
					<Image src={avatar} className='avatar' width={40} height={40} />
				)
			}, {
				title: '企业名称',
				dataIndex: 'company_name',
				key: 'company_name',
				align: 'center',
				className: 'pubname'
			}, {
				title: '成立日期',
				dataIndex: 'company_reg_date',
				key: 'company_reg_date',
				align: 'center'
			}, {
				title: '企业类型',
				dataIndex: 'company_type_name',
				key: 'company_type_name',
				align: 'center'
			}, {
				title: '融资类型',
				dataIndex: 'finance_name',
				key: 'finance_name',
				align: 'center',
			}, {
				title: '人员规模',
				dataIndex: 'scale_name',
				key: 'scale_name',
				align: 'center',
               
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							 <p className='color1' onClick={()=>{
								this.setState({
									editData: item,
									type: 'business',
									all: true,
									visible: true,
								})
							 }}>查看详情</p>
						</div>
					)
				}
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy:info.orderBy,
            company_type:this.state.company_type,
            scale_id:this.state.scale_id
		}
		global.Ajax.hhtc_request('company/companyList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			visible: true
		})
	}
	// 删除
	del(data) {
		var url = 'user/userEnable', req = { user_id: data.user_id }
		this.delmask.del(url, req,"确定要"+(data.status==1?"冻结":"解冻")+"用户吗?")
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入用户姓名查询'
						className='borderbai'
						onChange={(e) => {
							
							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
                    <SelectList
						type="companyType"
						className='marginr12 borderbai'
						style={{ width: 160 }}
						placeholder="请选择企业类型"
						onChange={(role_id) => {
							this.setState({
								company_type:role_id||0,
							}, () => {
								this.refresh()
							})
						}}
					/>
					 <SelectList
						type="scaleList"
						className='marginr12 borderbai'
						style={{ width: 160 }}
						placeholder="请选择企业规模"
						onChange={(role_id) => {
							this.setState({
								scale_id:role_id||0,
							}, () => {
								this.refresh()
							})
						}}
					/>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>企业列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
						
					/>
				</div>
				{/* 查看企业信息 */}
				<CustomModal
					visible={this.state.visible}
					width={388}
					closable={false}
					className='ginfobox'
					onCancel={this.onCancel.bind(this)}
				>
					{/* <BusinessInfo data={this.state.editData} /> */}
					<HRInfo data={this.state.editData} type={this.state.type} all={this.state.all} onClose={this.onCancel.bind(this)} />
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			editData: {},
			type: '',
			all: false,
		})
	}
}
