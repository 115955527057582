import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin } from 'antd'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <Modal
                centered={true}
                destroyOnClose={true}
                footer={null}
                closeIcon={(<p className='iconfont icon-guanbi'></p>)}
                {...this.props}
            ></Modal>
        )
    }
}
