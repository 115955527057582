import React from 'react';
import { Input, Button, Select, Switch, Form, message } from 'antd'
import SelectList from '../../common/SelectList';

const { Option } = Select
const icon = require('../icon.json')

const initData={
    level: 1,
    name: '',
    path: '',
    route: '',
    icon: '',
    sort: '',
    display: true,
    needLog: false,
    pid: 0,
}

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuLevelList: [
                { value: 1, label: '一级菜单' },
                { value: 2, label: '二级菜单' },
                { value: 3, label: '三级菜单' },
            ],
            iconList: icon,
            loading: false,
            data: props.data || initData,
            level: props.level || 1,
            pid: props.pid || 0,
            name: '',
            path: '',
            route: '',
            firMenu: [],
            secMenu: [],
            // fid: props.fid || 0,
            sid: props.sid || 0,
        }
    }
    componentDidMount() {
        this.getMenus(1, 0)
        if (this.state.level > 1) {
            this.getMenus(2, this.state.pid)
        }
    }
    getMenus(type, pid) {
        global.Ajax.hhtc_request('menu/getMenusByPid', { pid: pid }).then(res => {
            if (res.code == 1) {
                var data = []
                for (var i in res.data) {
                    var obj = {
                        value: res.data[i].id * 1,
                        label: res.data[i].name
                    }
                    data.push(obj)
                }
                if (type == 1) {
                    this.setState({
                        firMenu: data
                    })
                } else {
                    this.setState({
                        secMenu: data
                    })
                }
            }
        })
    }
    onFinish = (values) => {
        this.setState({
            loading: true,
        }, () => {
            if (this.state.level == 2) {
                values.pid = this.state.pid
                values.icon = ''
            } else if (this.state.level == 3) {
                values.pid = this.state.sid
                values.icon = ''
            }else{
                values.pid=0;
            }
            values.display = values.display ? 1 : 0;
            values.needLog = values.needLog ? 1 : 0;
            var url='menu/addMenu';
            if(this.props.type=='edit'){  //编辑
                url='menu/editMenu';
                values.id=this.state.data.id;
            }
            global.Ajax.hhtc_request(url, values).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2)
                    this.props.onOk()
                    this.setState({
                        data: initData
                    })
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    initialValues={{
                        level: this.state.level,
                        icon: this.state.data.level==1?this.state.data.icon:undefined,
                        display: this.props.type=='edit'?this.state.data.display:true,
                        needLog: this.props.type=='edit'?this.state.data.needLog:false,
                        path: this.props.type=='edit'?this.state.data.path:'',
                        route: this.props.type=='edit'?this.state.data.route:'',
                        sort: this.props.type=='edit'?(this.state.data.sort || ''):'',
                        name: this.props.type=='edit'?this.state.data.name:'',
                    }}
                    labelCol={{span: 6}}
                    className='editMenu'
                >
                    <Form.Item name="level" label="菜单等级：" rules={[{ required: true, message: '请选择菜单等级!' }]}>
                        <SelectList
                            name="level"
                            placeholder='菜单等级'
                            data={this.state.menuLevelList}
                            value={this.state.level}
                            onChange={(level) => {
                                this.setState({
                                    level
                                })
                            }}
                            // disabled={this.props.type=='edit'?true:false}
                            disabled
                        />
                    </Form.Item>
                    {this.state.level > 1 && this.state.firMenu.length > 0 && <Form.Item label="一级菜单：">
                        <SelectList
                            placeholder='请选择菜单'
                            data={this.state.firMenu}
                            value={this.state.pid * 1}
                            onChange={(pid) => {
                                this.setState({ pid })
                            }}
                            disabled={this.props.type=='edit'?true:false}
                        />
                    </Form.Item>}
                    {this.state.level > 2 && this.state.secMenu.length > 0 && <Form.Item label="二级菜单：">
                        <SelectList
                            placeholder='请选择菜单'
                            data={this.state.secMenu}
                            value={this.state.sid * 1}
                        />
                    </Form.Item>}
                    <Form.Item name="name" label="菜单名称：" rules={[{ required: true, message: '请输入菜单名称！' }]}>
                        <Input autoComplete="off" placeholder='请输入菜单名称' type='text' />
                    </Form.Item>
                    <Form.Item name="path" label="前端路由：">
                        <Input autoComplete="off" placeholder='请输入前端路由' type='text' />
                    </Form.Item>
                    <Form.Item name="route" label="后端路由：">
                        <Input autoComplete="off" placeholder='请输入后端路由' type='text' />
                    </Form.Item>
                    {this.state.level == 1 && <Form.Item name='icon' label={`图标：`} rules={[{ required: true, message: '请选择图标！' }]}>
                        <Select
                            className='pubSelt'
                            dropdownClassName='fontSize28'
                            style={{ fontSize: 18 }}
                            placeholder='请选择图标'
                            allowClear
                            suffixIcon={(<span className='iconfont icon-xia'></span>)}
                            showSearch
                        >
                            {this.state.iconList.map((item, index) => (
                                <Option value={item.value} key={'' + index}>
                                    <span className={`iconfont ${item.value}`}></span>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>}
                    <Form.Item name="sort" label="排序：">
                        <Input autoComplete="off" placeholder='越小越靠前' type='text' />
                    </Form.Item>
                    <div className='flexCenter'>
                        <Form.Item name='display' label='显示状态：' valuePropName="checked" style={{ flex: 1 }}>
                            <Switch checkedChildren="显示" unCheckedChildren="隐藏" />
                        </Form.Item>
                        <Form.Item name='needLog' label='打印日志：' valuePropName="checked">
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                        </Form.Item>
                    </div>
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}
