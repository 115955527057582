import React from 'react';
import { Input, Button, Form,message,Spin } from 'antd';
import CheckBox from '../../common/CheckBox';  //多选框

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            indeterminate: false,
            list: [],
            loading: false,
            data: props.data,
            requestLoadingShow: true,
        }
    }
    componentDidMount() {
        global.Ajax.hhtc_request('role/addRoleGetMenus', {}).then(res => {
            if (res.code == 1) {
                var data = this.initData(res.data);
                for (var i in data) {
                    for (var j in data[i].child) {
                        data[i].child[j].child = this.initData(data[i].child[j].child)
                    }
                    data[i].child = this.initData(data[i].child)
                }
                this.setState({
                    list: data,
                })
            }
            this.setState({
                requestLoadingShow: false,
            })
        })
    }
    initData(arry) {
        let arryNew = []
        arry.map((item, index) => {
            arryNew.push(Object.assign({}, item, { value: item.id, label: item.name }))
        })
        return arryNew
    }
    checkIds=()=>{
        // console.log(this.role.state.checkedValues)
        if(this.role.state.checkedValues.length==0){
            return Promise.reject(new Error('请选择角色权限!'));
        }
        return Promise.resolve();
    }
    onFinish = (values) => {
        this.setState({
            loading: true,
        },()=>{
            values.ids=JSON.stringify(this.role.state.checkedValues);
            var url='role/addRole';
            if(this.props.type=='edit'){  //编辑
                url='role/editRole';
                values.role_id=this.state.data.role_id
            }
            global.Ajax.hhtc_request(url,values).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    initialValues={{
                        role_name: this.state.data.role_name,
                        describe: this.state.data.describe,
                    }}
                >
                    <div className='flexCenter'>
                        <Form.Item name="role_name" label="角色名称：" rules={[{ required: true, message: '请输入角色名称!' }]} style={{ flex: 1 }}>
                            <Input autoComplete="off" placeholder='请输入角色名称' type='text' />
                        </Form.Item>
                        <Form.Item name="describe" label="角色描述：" rules={[{ required: true, message: '请输入角色描述!' }]} style={{flex: 1,marginLeft: 48}}>
                            <Input autoComplete="off" placeholder='请输入角色描述' type='text' />
                        </Form.Item>
                    </div>
                    {this.state.requestLoadingShow && <Spin tip="加载中..." style={{ display: 'block', margin: '50px auto 0' }} />}
                    {!this.state.requestLoadingShow &&<Form.Item className='qxlistt' name="ids" label="角色权限：" rules={[{ validator: this.checkIds }]}>
                        <CheckBox ref={dom => this.role = dom} data={this.state.list} values={JSON.parse(this.state.data.ids)} />
                    </Form.Item>}
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}
