import React from 'react';
import { Button, Input, message } from 'antd'
import UploadList from '../common/UploadList';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            avatar: props.avatar || require('../imgs/default.png').default,
            username: props.username || '',
            fileList: [],
            loading: false,
        }
    }
    onOk() {
        this.setState({
            loading: true,
        },()=>{
            var req={
                avatar: this.state.avatar,
                username: this.state.username
            }
            global.Ajax.hhtc_request('admin/editAvatar',req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onRefresh()
                    this.props.onCancel()
                }else{
                    message.error(res.msg,1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <div className='uploadAvatar' onClick={() => {
                    this.uplist.setState({
                        visible: true,
                    })
                }}>
                    <img alt='' src={this.state.avatar} className='avatar' />
                    <p>修改头像</p>
                </div>
                <div className='flexCenter'>
                    <p>我的昵称：</p>
                    <Input placeholder='请输入昵称' type='text' value={this.state.username} onChange={(e)=>{
                        this.setState({
                            username: e.target.value
                        })
                    }} />
                </div>
                <div className='btnbox flexCenter flexEnd'>
                    <Button className='huibtn marginr8' onClick={() => {
                        this.props.onCancel()
                    }}>取消</Button>
                    <Button loading={this.state.loading} type='primary' onClick={this.onOk.bind(this)}>确定</Button>
                </div>
                {/* 文件库 */}
                <UploadList fileNum={1} ref={dom => this.uplist = dom} type={1} onOk={this.onUploadOk.bind(this)} />
            </div>
        )
    }
    onUploadOk(data){
        this.setState({
            avatar: data[0]
        })
        this.uplist.onCancel()
    }
}
