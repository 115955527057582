import React from 'react';
import { Button, Pagination, Modal, message, Empty, Spin } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import AddType from './AddType';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import CustomTable from '../../common/Table';

export default class CFAType extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            name: '',
            editData: {},
            reqUrl: "config/addCFAType",
            actType: "add",
            type: 16
        }
        this.columns = [
            {
                title: '序号',
                dataIndex: 'key',
                key: 'key',
                sorter: true,
                align: 'center'
            }, {
                title: '类型名称',
                dataIndex: 'name',
                key: 'name',
                align: 'center'
            }, {
                title: '操作',
                dataIndex: 'admin_id',
                key: 'admin_id',
                align: 'center',
                render: (id, item) => {
                    return (
                        <div className='flexAllCenter pubbtnbox'>
                            <p onClick={this.edit.bind(this, item)}>编辑</p>
                            <p onClick={this.del.bind(this, item)}>删除</p>
                        </div>
                    )
                }
            }
        ]
    }

    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            name: this.state.name,
            orderBy: info.orderBy,
            type: this.state.type
        }
        global.Ajax.hhtc_request('config/xzList', req).then(res => {
            callback(res)
        })
    }
    // 编辑
    edit(data) {
        this.setState({
            editData: data,
            actType: 'edit',
            visible: true,
            reqUrl: "config/editXz"
        })
    }
    // 删除
    del(data) {
        var url = 'config/delXz', req = { id: data.id }
        this.delmask.del(url, req)
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Input
                        placeholder='请输入事务所性质名称'
                        className='borderbai'
                        onChange={(e) => {
                            this.setState({
                                name: e.target.value
                            }, () => {
                                this.refresh()
                            })
                        }}
                    />
                    <Button type='primary' onClick={() => {
                        this.setState({
                            visible: true,
                            reqUrl: "config/addXz",
                            actType: "add"
                        })
                    }}>新增事务所性质</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>事务所性质列表</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                {/* 添加管理员 */}
                <CustomModal
                    visible={this.state.visible}
                    width={360}
                    title={`${this.state.actType == 'edit' ? '编辑' : '添加'}事务所性质`}
                    onCancel={this.onCancel.bind(this)}
                >
                    <AddType data={this.state.editData} reqUrl={this.state.reqUrl} type={this.state.type} actType={this.state.actType} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
                </CustomModal>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
            </React.Fragment>
        )
    }
    onOk() {
        this.refresh()
        this.onCancel()
    }
    onCancel() {
        this.setState({
            visible: false,
            editData: {}
        })
    }
}
