import '@wangeditor/editor/dist/css/style.css' // 引入 css

import React, { useState, useEffect,useRef,useImperativeHandle } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'

import UploadList from './UploadList';

let onOk=null;

function MyEditor(props,ref) {
    const inputEl = useRef(null);
    const toolbar = useRef(null);
    let type=1;
    // editor 实例
    const [editor, setEditor] = useState(null)                   // JS 语法

    // 编辑器内容
    const [html, setHtml] = useState('')

    // 模拟 ajax 请求，异步设置 html
    useEffect(() => {
        // setTimeout(() => {
        //     setHtml('<p>hello world</p>')
        // }, 1500)
    }, [])
    useImperativeHandle(ref, () => ({
        setContent,
    }))
    const setContent=(data)=>{
        setHtml(data)
    }

    // 工具栏配置
    const toolbarConfig = {
        
     }                        // JS 语法

    // 编辑器配置
    let editorConfig = {                         // JS 语法
        placeholder: '请输入内容...',
        readOnly: props.readOnly || false,
        MENU_CONF:{

        }
    }
    editorConfig.MENU_CONF['uploadImage'] = {
        // 自定义选择图片
        customBrowseAndUpload(insertFn) {              // JS 语法
            // 自己选择文件
            // 自己上传文件，并得到图片 url alt href
            // 最后插入图片
            // insertFn(url, alt, href)
            console.log(insertFn);
            type=1;
            inputEl.current.setState({
                visible: true,
            })
            onOk=insertFn;
        }

    }
    editorConfig.MENU_CONF['uploadVideo'] = {
        // 自定义选择图片
        customBrowseAndUpload(insertFn) {              // JS 语法
            // 自己选择文件
            // 自己上传文件，并得到图片 url alt href
            // 最后插入图片
            // insertFn(url, alt, href)
            console.log(type);
            type=2;
            inputEl.current.setState({
                visible: true,
            })
            onOk=insertFn;
        }
        
    }
    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100}}>
                <Toolbar
                   ref={toolbar}
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {
                        console.log(editor.getConfig())
                        setHtml(editor.getHtml())
                        let value=editor.getHtml();
                        props.changeValue&&props.changeValue(value)
                    }}
                    mode="default"
                    style={{ height: '140px', overflowY: 'hidden' ,...props.style}}
                />
            </div>
            {/* 文件库 */}
            <UploadList fileNum={1} ref={inputEl} type={type} onOk={(data)=>{
                console.log(data);
                onOk(data[0],'图片',data[0]);
                inputEl.current.onCancel();
            }} />
        </>
    )
}

const Editors=React.forwardRef(MyEditor)

export default Editors