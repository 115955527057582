import React, { useId } from 'react';
import { DatePicker, Pagination, Modal, message, Select, Image, Tooltip } from 'antd'
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import moment from 'moment';
import CustomModal from '../../common/Modal';
import SelectList from '../../common/SelectList';
import InputModal from './InputModal';
import Refuse from '../../common/Refuse';  //拒绝弹窗
const { RangePicker } = DatePicker;
export default class Complain extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			type: 0,//职务类型ID
			name: "",
			times: []
		}
		this.columns = [
			{
				title: '序号',
				width: 80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: 'HR信息',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				width: 260,
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.hr_avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.hr_name}</p>
								<p>{info.job_name}·{info.hr_mobile}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '求职者信息',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.user_avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.user_name}</p>
								<p>{info.user_mobile}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '投诉类型',
				dataIndex: 'type_name',
				key: 'type_name',
				align: 'center'
			}, {
				title: '投诉内容',
				dataIndex: 'content',
				key: 'content',
				align: 'center'
			}, {
				title: '图片',
				dataIndex: 'imgs',
				key: 'imgs',
				align: 'center',
				width: 300,
				render: (imgs) => {
					var imgArr = [];
					if (imgs) {
						imgArr = imgs.split(",");
					}
					return (
						<div className='imgsDiv'>
							<Image.PreviewGroup >
								{imgArr.map((img, key) => (
									<Image
										width={40}
										height={40}
										key={key}
										src={img}

									/>
								))}
							</Image.PreviewGroup>
						</div>
					)
				}
			}, {
				title: '投诉时间',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center',
			}, {
				title: '备注',
				dataIndex: 'remarks',
				key: 'remarks',
				align: 'center',
				ellipsis: true,
				render: remarks => (
					<Tooltip title={remarks}>
						{remarks || '-'}
					</Tooltip>
				)
			}, {
				title: '操作',
				dataIndex: 'id',
				key: 'id',
				align: 'center',
				render: (_, item) => {
					return (
						<p className={item.status == 0 ? 'color1 cursor' : 'color2'} onClick={() => {
							if (item.status !== 0) {
								return
							}
							this.refe.setState({
								visible: true,
								user_id: item.id,
							})
						}}>{item.status == 0 ? '处理' : '已处理'}</p>
					)
				}
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy: info.orderBy,
			type: this.state.type,
			times: this.state.times
		}
		global.Ajax.hhtc_request('job/complainLog', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(job_id, editStatus, tips) {
		this.setState({
			job_id, editStatus, tips, visible: true
		})
	}

	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入HR或者用户名称查询'
						className='borderbai'
						onChange={(e) => {

							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}
					/>
					<SelectList
						type="complainList"
						className='marginr12 borderbai'
						placeholder="请选择投诉类型"
						style={{ width: 160 }}
						onChange={(role_id) => {
							this.setState({
								type: role_id || 0,
							}, () => {
								this.refresh()
							})
						}}
					/>
					<RangePicker className='borderbai marginr12' onChange={(time, times) => {
						if (times[0] != "") {
							this.setState({
								times
							}, () => {
								this.refresh()
							})
						} else {
							this.setState({
								times: []
							}, () => {
								this.refresh()
							})
						}
					}} />
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>投诉列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
					/>
				</div>
				{/* 拒绝弹窗 */}
				<Refuse
					ref={dom => this.refe = dom}
					type={this.state.type || ''}
					remarks={this.state.remarks || ''}
					onFinish={(req) => {
						if (this.state.type == 'readOnly') {
							this.refe.onCancel()
							return
						}
						this.refe.setState({
							// loading: true,
						}, () => {
							req.id = this.refe.state.user_id;
							global.Ajax.hhtc_request('job/chuliComplain', req).then(res => {
								if (res.code == 1) {
									message.success(res.msg, 1.2)
									this.onOk()
								} else {
									message.error(res.msg, 1.2)
								}
								this.refe.setState({
									loading: false,
								}, () => {
									if (res.code == 1) {
										this.refe.onCancel()
									}
								})
							})
						})
					}}
					onCancel={() => {
						this.setState({
							type: '',
							remarks: '',
						})
					}}
				/>
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={this.state.tips}
					onCancel={this.onCancel.bind(this)}
				>
					{/* <InputModal job_id={this.state.job_id} status={this.state.editStatus} reqUrl="job/enableJob" onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} /> */}
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			status: 1,
			job_id: 0
		})
	}
}
