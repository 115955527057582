import React from 'react';
import { Button, Pagination, Modal, Empty, Image, message, Spin,Switch } from 'antd'
import AddBasic from './AddBasic';
import TabbleList from '../../common/TabbleList';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			size: 10,
			visible: false,
			orderBy: '',
			editData: {},
			type: '',
		}
	}
	refresh(){
		this.tabblelist.onRefresh()
	}
	changeVisible(key) {
		this.setState({
			[key]: true
		})
	}
	getList(page, callback) {
		var orderBy = this.state.orderBy;
		var req = {
			page: page,
			size: this.state.size,
			orderBy
		}
		global.Ajax.hhtc_request('setting/settingList', req).then(res => {
			if (res.code == 1) {
				callback(res.data)
			}
		})
	}

	// 编辑
	edit(data) {
		this.setState({
			visible: true,
			editData: data,
			type: 'edit'
		})
	}
	// 删除
	del(data) {
		var url = 'setting/delSetting', req = { id: data.id }
		this.delmask.del(url, req)
	}
	onRefresh(page, callback) {
		this.getList(page, callback)
	}
	renderHead() {
		return (
			<div className='head flex'>
				<p className='flexAllCenter cursor' style={{ width: 80 }}>
					序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
						var orderBy = this.state.orderBy;
						if (orderBy == 'desc') {
							orderBy = 'asc'
						} else {
							orderBy = 'desc'
						}
						this.setState({
							orderBy
						}, () => {
							this.refresh()
						})
					}}></span>
				</p>
				<p className='row2'>配置ID</p>
				<p className='row2'>配置名称</p>
				<p className='rowFlex'>配置值</p>
				<p className='row2 flexAllCenter cursor'>值类型</p>
				<p className='row15'>操作</p>
			</div>
		)
	}
	renderItem(data) {
		return (
			<React.Fragment>
				{data.map((item, index) => (
					<div className='flex' key={'' + index}>
						<p style={{ width: 80 }}>{Helper.getNum(index, this.tabblelist.state.total, this.tabblelist.state.size, this.tabblelist.state.page, this.state.orderBy)}</p>
						<p className='row2'>{item.id}</p>
						<p className='row2'>{item.title}</p>
						<p className='rowFlex' style={{ margin: item.type == 3 ? '-9px 0' : '' }}>
							{(item.type == 1 || item.type == 2) && <React.Fragment>{item.value}</React.Fragment>}
							{item.type == 3 && <Image src={item.value} />}
							{item.type == 4 && <React.Fragment>图文内容请在详情中查看</React.Fragment>}
							{item.type == 5 && <Switch checkedChildren="开" unCheckedChildren="关" checked={item.value == 1 ? true : false} disabled />}
						</p>
						<p className='row2'>
							{item.type == 1 && <Button type='primary' size='small'>文本</Button>}
							{item.type == 2 && <Button type='primary' size='small' className='fu3'>数字</Button>}
							{item.type == 3 && <Button type='primary' size='small' className='fu1'>图片</Button>}
							{item.type == 4 && <Button type='primary' size='small' className='fu2'>图文</Button>}
							{item.type == 5 && <Button type='primary' size='small' className='fu2'>开关</Button>}
						</p>
						<div className='row15 flexAllCenter pubbtnbox'>
							<p onClick={this.edit.bind(this, item)}>编辑</p>
							{item.canDel == 1 && <p onClick={this.del.bind(this, item)}>删除</p>}
							{/* <Button
								type='primary'
								icon={(<span className='iconfont icon-bianji marginr4'></span>)}
								onClick={this.edit.bind(this, item)}
							>编辑</Button>
							{item.canDel == 1 && <Button
								type='primary'
								icon={(<span className='iconfont icon-shanchu marginr4'></span>)}
								className='fu1 marginl8'
								onClick={this.del.bind(this, item)}
							>删除</Button>} */}
						</div>
					</div>
				))}
			</React.Fragment>
		)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Button type='primary' onClick={this.changeVisible.bind(this, 'visible')}>添加配置</Button>
				</div>
				<div className='bgbai basiclistt'>
					<h2 className='pubTit'>基本信息设置列表</h2>
					<TabbleList
						ref={dom => this.tabblelist = dom}
						size={this.state.size}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						renderHead={this.renderHead.bind(this)}  //表头
						renderItem={this.renderItem.bind(this)}  //表内容
					/>
				</div>
				{/* 添加配置 */}
				<CustomModal
					visible={this.state.visible}
					width={696}
					title='添加配置'
					onCancel={this.onCancel.bind(this)}
				>
					<AddBasic type={this.state.type} data={this.state.editData} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			editData: {}
		})
	}
}
