import { Image } from 'antd';
import React from 'react';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div style={{ position: 'relative', paddingTop: 1 }}>
                <div className='wrapbox flexColumn'>
                    <div className='boxx' style={{ background: '#F2F7FB' }}>
                        <h1 className='name'>提成律师</h1>
                        <div className='flwp' style={{ marginTop: 10 }}>
                            <p className='bq bgfff flexCenter'>
                                <img src={require('../../imgs/dz.png').default} style={{ display: 'block', width: 12, marginRight: 4 }} />
                                重庆市·渝北区</p>
                            <p className='bq bgfff'>本科</p>
                            <p className='bq bgfff'>3年经验</p>
                        </div>
                        <div className='bgfff padd flexCenter'>
                            <div className='avatar' style={{ width: 44, height: 44, }}>
                                <Image
                                    width={44}
                                    height={44}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                                <img alt='' src={require('../../imgs/vip.png').default} className='sex' />
                            </div>
                            <div style={{ flex: 1, marginLeft: 10 }}>
                                <div className='flexCenter'>
                                    <p className='name' style={{ fontSize: 16, fontWeight: 'bold', marginRight: 6 }}>林琳琳</p>
                                    <p className='p4'>刚刚活跃</p>
                                </div>
                                <p className='p1' style={{ fontSize: 12 }}>企业hr·佳士律师事务所</p>
                            </div>
                        </div>
                        <div className='bgfff padd' style={{ marginTop: 10 }}>
                            <h1 className='p1' style={{ fontSize: 18 }}>职位详情</h1>
                            <div className='flwp zwlist' style={{ marginRight: -15, marginTop: 10 }}>
                                <p className='bq'>专职律师</p>
                                <p className='bq'>专职律师</p>
                                <p className='bq'>诉讼</p>
                                <p className='bq'>专职律师</p>
                                <p className='bq'>专职律师</p>
                            </div>
                            <p className='p1'>岗位职责： 1 主要谈案领域：婚姻纠纷、民事纠纷 2. 负责当事人与企业客户接待与谈案工作，并签署委托代理合同； 3.在合伙人律师指导下独立洽谈指派的案件，并对谈案结果负责； 4. 分析案件情况、厘清法律关系、把控诉讼走向并预判案件结果。</p>
                        </div>
                        <div className='bgfff padd' style={{ marginTop: 10 }}>
                            <div className='flexCenter'>
                                <div className='avatar' style={{ width: 44, height: 44, }}>
                                    <Image
                                        width={44}
                                        height={44}
                                        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                    />
                                </div>
                                <div style={{ flex: 1, marginLeft: 10 }}>
                                    <p className='name' style={{ fontSize: 16, fontWeight: 'bold', marginRight: 6 }}>林琳琳</p>
                                    <p className='p1' style={{ fontSize: 12 }}>企业hr·佳士律师事务所</p>
                                </div>
                            </div>
                            <div className='mapBox' style={{height: 200,background: '#F2F7FB',marginTop: 15,}}></div>
                        </div>
                    </div>
                </div>
                <img alt='' src={require('../../imgs/shouj.png').default} className='boximg' />
            </div>
        )
    }
}
