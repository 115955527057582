import React from 'react';
import { Button, Pagination, Modal, message, Select, Image } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
export default class UserRechargeLog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			user_type:0,
            charge_type:0,
            name:""
		}
		this.columns = [
			{
				title: '序号',
                width:80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '用户名称',
				dataIndex: 'name',
				key: 'name',
				align: 'center'
			}, {
				title: '用户类型',
				dataIndex: 'user_type',
				key: 'user_type',
				align: 'center',
                render:(user_type)=>{
                    if(user_type==1){
                        return "普通用户";
                    }else{
                        return "企业用户";
                    }
                }
			}, {
				title: '充值类型',
				dataIndex: 'charge_type',
				key: 'charge_type',
				align: 'center',
                render:(charge_type)=>{
                    if(charge_type==1){
                        return "微信支付";
                    }else{
                        return "后台充值";
                    }
                }
			}, {
				title: '订单编号',
				dataIndex: 'file_name',
				key: 'file_name',
				align: 'center',
                render:(file_name,info)=>(
                    <>
                    <p>系统单号:{info.order_sn}</p>
                    {info.charge_type==1 &&  <p>三方单号:{info.third_order_sn}</p>}
                    </>
                )
			}, {
				title: '订单金额',
				dataIndex: 'money',
				key: 'money',
				align: 'center'
			}, {
				title: '充值时间',
				dataIndex: 'pay_time',
				key: 'pay_time',
				align: 'center'
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy:info.orderBy,
            charge_type:this.state.charge_type,
            user_type:this.state.user_type
		}
		global.Ajax.hhtc_request('user/userRechargeLog', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			visible: true
		})
	}
	// 删除
	del(data) {
		var url = 'user/userEnable', req = { user_id: data.user_id }
		this.delmask.del(url, req,"确定要"+(data.status==1?"冻结":"解冻")+"用户吗?")
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入用户姓名查询'
						className='borderbai'
						onChange={(e) => {
							
							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
                    <Select
                        
                        allowClear
                        className='marginr12 borderbai'
                        style={{
                            width:150
                        }}
                        onChange={(e)=>{
                            console.log(e)
                            if(e==undefined){
                                e=0;
                            }
                            this.setState({
                                user_type:e
                            },()=>{
                                this.refresh();
                            })
                        }}
                        placeholder="用户类型"
                        options={[
                            {
                            value:1,
                            label: '普通用户',
                            },
                            {
                            value: 2,
                            label: '企业用户',
                            }
                        ]}
                    />
                     <Select
                        
                        allowClear
                        className='marginr12 borderbai'
                        style={{
                            width:150
                        }}
                        onChange={(e)=>{
                            console.log(e)
                            if(e==undefined){
                                e=0;
                            }
                            this.setState({
                                charge_type:e
                            },()=>{
                                this.refresh();
                            })
                        }}
                        placeholder="充值类型"
                        options={[
                            {
                            value: 1,
                            label: '微信支付',
                            },
                            {
                            value: 2,
                            label: '后台支付',
                            }
                        ]}
                    />
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>用户简历文件列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
						
					/>
				</div>
				
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			editData: {}
		})
	}
}
