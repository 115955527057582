import React, { useId } from 'react';
import { Button, Pagination, Modal, message, Select, Image } from 'antd'
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import moment from 'moment';
import CustomModal from '../../common/Modal';
import SelectList from '../../common/SelectList';
import InputModal from './InputModal';
export default class JobList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			job_type_id: 0,//职务类型ID
			name: "",
			status: 0,
			job_id: 0,
			tips: "",
			editStatus: 0
		}
		this.columns = [
			{
				title: '序号',
				width: 80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '发布人',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				width: 240,
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.hr_avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.hr_name}</p>
								<p>{info.hr_job_name}·{info.hr_mobile}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '企业信息',
				dataIndex: 'user_type',
				key: 'user_type',
				align: 'center',
				width: 200,
				className: 'pubname',
				render: (_, info) => {
					return (
						<>
							<p>{info.company_name}</p>
							<p>{info.team_name}</p>
						</>
					);
				}
			}, {
				title: '职务信息',
				dataIndex: 'user_type',
				key: 'user_type',
				align: 'center',

				className: 'pubname',
				render: (_, info) => {
					return (
						<>
							<p>{info.job_type_name}</p>
							<p>{info.wage_max == 0 ? "面议" : `${info.wage_min}k-${info.wage_max}k`}</p>
						</>
					);
				}
			}, {
				title: '地区',
				dataIndex: 'city_info',
				key: 'city_info',
				align: 'center'
			}, {
				title: '状态',
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				render: (status) => {
					if (status == 1) {
						return <p className="color4">待审核</p>;
					} else if (status == 2) {
						return <p className="color1">开放中</p>;
					} else if (status == 3) {
						return <p className="color2">已关闭</p>;
					} else if (status == 4) {
						return <p className="color3">已驳回</p>;
					}
				}
			}, {
				title: '审核备注',
				dataIndex: 'remarks',
				key: 'remarks',
				align: 'center',
				render: (remarks) => (
					remarks || "-"
				)
			}, {
				title: '发布时间',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center'
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							{item.status == 1 &&
								<>
									<p className='color1' onClick={this.edit.bind(this, item.job_id, 2, "通过审核")}>审核通过 </p>
									<p className='color3' onClick={this.edit.bind(this, item.job_id, 4, "审核驳回")}>审核驳回 </p></>
							}
							{item.status == 2 &&
								<p className='color1' onClick={this.edit.bind(this, item.job_id, 4, "下架职务")}>下架职务 </p>
							}
							{item.status == 4 &&
								<p className='color1' onClick={this.edit.bind(this, item.job_id, 2, "上架职务")}>上架职务 </p>
							}
						</div>
					)
				}
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy: info.orderBy,
			status: this.state.status,
			job_type_id: this.state.job_type_id
		}
		global.Ajax.hhtc_request('job/jobList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(job_id, editStatus, tips) {
		this.setState({
			job_id, editStatus, tips, visible: true
		})
	}
	// 删除
	del(data) {
		var url = 'user/userEnable', req = { user_id: data.user_id }
		this.delmask.del(url, req, "确定要" + (data.status == 1 ? "冻结" : "解冻") + "用户吗?")
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入团队名称、公司名称、HR名称查询'
						className='borderbai'
						onChange={(e) => {

							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
					<SelectList
						type="jobTypeList"
						className='marginr12 borderbai'
						placeholder="请选择职务类型"
						style={{ width: 160 }}
						onChange={(role_id) => {
							this.setState({
								job_type_id: role_id || 0,
							}, () => {
								this.refresh()
							})
						}}
					/>
					<Select

						allowClear
						className='marginr12 borderbai'
						style={{
							width: 150
						}}
						onChange={(e) => {
							console.log(e)

							this.setState({
								status: e || 0
							}, () => {
								this.refresh();
							})
						}}
						placeholder="职务状态"
						options={[
							{
								value: 1,
								label: '待审核',
							},
							{
								value: 2,
								label: '开放中',
							},
							{
								value: 3,
								label: '已关闭',
							},
							{
								value: 4,
								label: '审核驳回',
							}
						]}
					/>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>职务列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'

					/>
				</div>
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={this.state.tips}
					onCancel={this.onCancel.bind(this)}
				>
					<InputModal job_id={this.state.job_id} status={this.state.editStatus} reqUrl="job/enableJob" onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			status: 1,
			job_id: 0
		})
	}
}
