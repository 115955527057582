import React from 'react';
import { Button, Form, Input as AntInput, DatePicker, Tooltip, message, Spin, Empty } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import Del from '../../common/Del';
import CustomModal from '../../common/Modal';

export default class MajorAreas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addShow: false,
            type: null,
            pid: [0],
            list: [],
            activeIds: [],
            loading: false,
            activeId: 0,
            selectData: [],
            requestLoadingShow: false,
            // 编辑
            id: '',
            name: '',
        }
    }
    componentDidMount() {
        this.getList(0)
    }
    refresh(){
		let index = this.state.pid.length > 0 ? this.state.pid.length - 1 : 0;
		let list=this.state.list,activeIds=this.state.activeIds;
		if(this.state.type!='sec'){
			index=0;
            list=[];
            activeIds=[];
		}else{
            list[index]=[];
        }
		this.setState({
			list,
            activeIds,
		}, () => {
			this.getList(index)
		})
        // this.setState({
        //     list: []
        // },()=>{
        //     this.getList(0)
        // })
    }
    getList(index) {
        this.setState({
            requestLoadingShow: true,
        }, () => {
            global.Ajax.hhtc_request('config/majorAreas', { pid: this.state.pid[index] }).then(res => {
                if (res.code == 1) {
                    var list = this.state.list;
                    list[index] = res.data;
                    this.setState({
                        list
                    })
                }
                this.setState({
                    requestLoadingShow: false,
                })
            })
        })
    }
    // 删除
    del(data, e) {
        e.preventDefault()
        e.stopPropagation()
        var url = 'config/delMajorAreas', req = { id: data.id }
        this.delmask.del(url, req)
    }
    add(id, data, e) {
        e.preventDefault()
        e.stopPropagation()
        this.setState({
            type: 'sec',
            addShow: true,
            activeId: id,
            selectData: this.initData(data),
        })
    }
    initData(arry) {
        let arryNew = []
        arry.map((item, index) => {
            arryNew.push(Object.assign({}, item, { value: item.id, label: item.name }))
        })
        return arryNew
    }
    edit(row,index, e) {
        e.preventDefault()
        e.stopPropagation()
        // var selectData=[];
        // if(index>0){
        //     selectData=this.initData(this.state.list[index-1])
        // }
        this.setState({
			actType: 'edit',
			type: 'sec',
            // type: 'edit',
            addShow: true,
            id: row.id,
            name: row.name,
            // selectData,
            activeId: row.pid
        },()=>{
            setTimeout(() => {
                this.formRef.setFieldsValue({
                    sort: row.sort,
                    name: row.name,
                })
            }, 100);
        })
    }
    selectRow(index, item) {
        var pid = this.state.pid,
            activeIds = this.state.activeIds,
            list = this.state.list;
        if (pid.length > index) {
            pid = pid.slice(0, index)
            list = list.slice(0, index)
        }
        if (activeIds.length > index - 1) {
            activeIds = activeIds.slice(0, index - 1)
        }
        // console.log(pid,activeIds,list,index)
        pid[index] = item.id;
        activeIds[index - 1] = item.id;
        console.log(pid);
        this.setState({
            pid,
            activeIds,
            list
        }, () => {
            this.getList(index)
        })
    }
    render() {
        return (
            <React.Fragment>
                <div className='flexCenter'>
                    <Input
                        placeholder='请输入相关信息查询'
                        className='borderbai'
                        onChange={(e) => {
                            this.search(e.target.value)
                        }}
                    />
                    <Button type='primary' onClick={() => {
                        this.setState({
                            type: 'first',
                            addShow: true,
                            activeId: 0,
                        })
                    }}>添加专业领域</Button>
                </div>
                <div className='bgbai'>
                    <h2 className='pubTit'>专业领域列表</h2>
                    <div className='perlist'>
                        {this.state.list.map((item, index) => (
                            <div className='item' key={String(index)}>
                                {item.map((row, k) => (
                                    <div className={`row flexCenter ${row.id == this.state.activeIds[index] ? 'active iconfont' : ''}`} key={String(k)} 
                                    onClick={()=>{
                                        if(row.pid==0){
                                            this.selectRow( index + 1, row)
                                        }

                                        
                                    }}>
                                        <p className='p1' title={row.name}>{row.name}</p>
                                        {row.pid==0 && <span className='iconfont icon-tianjia2' onClick={this.add.bind(this, row.id, item)}></span>}
                                        <span className='iconfont icon-bianji' onClick={this.edit.bind(this, row,index)}></span>
                                        <span className='iconfont icon-shanchu' onClick={this.del.bind(this, row)}></span>
                                    </div>
                                ))}
                                {/* 空数据 */}
                                {item.length == 0 && <Empty style={{ marginTop: 30 }} />}
                            </div>
                        ))}
                        {this.state.requestLoadingShow && <Spin tip="加载中..." style={{ marginTop: 40, marginLeft: 100, verticalAlign: 'top' }} />}
                    </div>
                </div>
                {/* 操作确认弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
                {/* 添加专业 */}
                <CustomModal
                    visible={this.state.addShow}
                    width={360}
                    title={`${this.state.actType == 'edit' ? '编辑' : '添加'}专业领域`}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        onFinish={this.onFinish}
                        ref={dom=>this.formRef=dom}
                        labelCol={{flex: '82px'}}
                    >
                        {/* {(this.state.type == 'sec') && <Form.Item
                            name='pid'
                            label='上级部门'
                            rules={[{ required: true, message: '请选择上级部门' }]}
                            initialValue={this.state.activeId}
                        >
                            <SelectList
                                data={this.state.selectData}
                                placeholder='请选择上级部门'
                            />
                        </Form.Item>} */}
                        <Form.Item
                            name='name'
                            label='领域名称：'
                            rules={[{ required: true, message: '请输入专业领域名称' }]}
                            // initialValue={this.state.name}
                        >
                            <AntInput autoComplete='off' placeholder='请输入专业领域名称' />
                        </Form.Item>
                        <Form.Item name="sort"  label="排序序号"  rules={[{ required: false, message: '请输入序号!' }]}>
                            <AntInput autoComplete="off" defaultValue={0} placeholder='请输入序号!' type='number' />
                        </Form.Item>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                    </Form>
                </CustomModal>
            </React.Fragment>
        )
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.pid = this.state.activeId
            if(req.sort===undefined){
                req.sort=0;
            }
            var url = 'config/addMajorAreas';
            if (this.state.actType == 'edit') {
                req.id = this.state.id;
                url = 'config/editMajorAreas';
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    this.onCancel()
                    message.success(res.msg, 1.2)
                    // this.getList(0)
                    this.refresh()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    onCancel() {
        this.setState({
            addShow: false,
            // type: null,
            activeId: null,
            selectData: [],
            actType: ''
        })
    }
}