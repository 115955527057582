import React from 'react';
import { Input, Button, Form, message ,InputNumber} from 'antd';
import SelectList from '../../common/SelectList'

export default class UserCharge extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pwd: ''
        }
    }
   
    onFinish = (req) => {
        this.setState({
            loading: true,
        },()=>{
            console.log(this.props,req)
            req.user_type=this.props.type;
            req.user_id=this.props.data.user_id;
            
            global.Ajax.hhtc_request(this.props.reqUrl,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 6}}
                    labelAlign="left"
                    initialValues={this.props.data}
                >
                    
                    <Form.Item name="nickname"  label="用户姓名" rules={[{ required: true, message: '请输入用户姓名!' }]}>
                        <Input autoComplete="off" disabled placeholder='请输入用户姓名!' type='text' />
                    </Form.Item>
                   
                    <Form.Item name="month" label="充值月数"  rules={[{ required: true, message: '请输入充值月数!' }]}>
                        <Input autoComplete="off"  placeholder='请输入充值月数' type='number' />
                    </Form.Item>
                  
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}