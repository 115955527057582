import React from 'react';
import { Button, Pagination, Modal, message, Select, Image } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
export default class UserResumeFile extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			is_auth:-1,
            is_resume:-1,
            name:"",
            editData:{}
		}
		this.columns = [
			{
				title: '序号',
                width:80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '姓名',
				dataIndex: 'nickname',
				key: 'nickname',
				align: 'center'
			}, {
				title: '文件名称',
				dataIndex: 'file_name',
				key: 'file_name',
				align: 'center',
                render:(file_name,info)=>(
                    <p onClick={()=>{
                        window.open(info.file_url,"_blank")
                    }} className='color1 pointer'>{file_name}</p>
                )
			}, {
				title: '添加时间',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center'
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy:info.orderBy
		}
		global.Ajax.hhtc_request('user/userResumeList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			visible: true
		})
	}
	// 删除
	del(data) {
		var url = 'user/userEnable', req = { user_id: data.user_id }
		this.delmask.del(url, req,"确定要"+(data.status==1?"冻结":"解冻")+"用户吗?")
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入用户姓名查询'
						className='borderbai'
						onChange={(e) => {
							
							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
                   
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>用户简历文件列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
						
					/>
				</div>
				
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			editData: {}
		})
	}
}
