import React from 'react';
import { Input, Button, Form, message ,InputNumber} from 'antd';
export default class InputModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pwd: ''
        }
    }
   
    onFinish = (req) => {
        this.setState({
            loading: true,
        },()=>{
            
            req.status=this.props.status
            if(this.props.job_id){
                req.job_id=this.props.job_id;
            }
            global.Ajax.hhtc_request(this.props.reqUrl,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 6}}
                    labelAlign="left"
                    initialValues={this.props.data}
                >
                    <Form.Item name="remarks" label="审核备注" rules={[{ required: true, message: '请输入审核备注!' }]}>
                        <Input.TextArea autoComplete="off" placeholder='请输入审核备注!' type='text' />
                    </Form.Item>
                    
                  
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}