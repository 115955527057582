import React from 'react';
import {Button, Pagination, Modal, message, Select, Image, Tooltip} from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import CustomModal from '../../common/Modal';
import BusinessInfo from './BusinessInfo';  // 企业信息
import HRInfo from './HRInfo';  // hr信息
export default class CompanyTeam extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			companyVisible:false,
			teamVisible:false,
            name:"",
			company_id:0,
			team_id:0
		}
		this.columns = [
			{
				title: '序号',
                width:80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '团队名称',
				dataIndex: 'team_name',
				key: 'team_name',
				width:150,
				align: 'center'
			}, {
				title: '企业名称',
				width:200,
				dataIndex: 'company_name',
				key: 'company_name',
				align: 'center'
			}, {
				title: '创建者姓名',
				dataIndex: 'create_name',
				width:120,
				key: 'create_name',
				align: 'center'
			}, {
				title: '团队简介',
				dataIndex: 'team_desc',
				key: 'team_desc',
				width:150,
				align: 'left',
				render:(desc)=>(
					<Tooltip title={desc} ><p className={"line1"}>{desc}</p></Tooltip>
				)
			}, {
				title: '地址信息',
				dataIndex: 'address',
				key: 'address',	width:150,
				align: 'left',
				render:(desc)=>(
					<Tooltip title={desc} ><p className={"line1"}>{desc}</p></Tooltip>
				)
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				width:200,
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							<p className='color1' onClick={()=>{
								this.setState({
									editData: item,
									type: 'team',
									visible:true,
								})
							}} >团队详情</p>
							 <p className='color1'  onClick={()=>{
								this.setState({
									editData: item,
									type: 'business',
									visible: true,
								})
							}} >公司详情</p>
						</div>
					)
				}
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy:info.orderBy
		}
		global.Ajax.hhtc_request('company/companyTeam', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			visible: true
		})
	}

	// 删除
	del(data) {
		var url = 'user/userEnable', req = { user_id: data.user_id }
		this.delmask.del(url, req,"确定要"+(data.status==1?"冻结":"解冻")+"用户吗?")
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入团队名称、公司名称、HR名称查询'
						className='borderbai'
						onChange={(e) => {

							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>

				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>企业团队列表</h2>
					<CustomTable
						ref={dom=>this.tabblelist=dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'

					/>
				</div>
				{/* 查看企业信息 */}
				<CustomModal
					visible={this.state.visible}
					width={388}
					closable={false}
					className='ginfobox'
					onCancel={this.onCancel.bind(this)}
				>
					{/* <BusinessInfo /> */}
					<HRInfo data={this.state.editData} type={this.state.type} onClose={this.onCancel.bind(this)} />
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			editData:{},
			type: null,
		})
	}
}
