import React from 'react';
import { Input } from 'antd';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <Input
                placeholder='请输入'
                prefix={(<span className='iconfont icon-sousuo marginr4'></span>)}
                allowClear
                {...this.props}
                className={`pubInpt marginr12 ${this.props.className}`}
            />
        )
    }
}