import React, { useId } from 'react';
import { DatePicker, Popconfirm, Modal, message, Select, Image } from 'antd'
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import moment from 'moment';
import CustomModal from '../../common/Modal';
import SelectList from '../../common/SelectList';
import InputModal from './InputModal';
import PostDetail from '../user/PostDetail';

const { RangePicker } = DatePicker;
export default class Post extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			status: -1,//职务类型ID
			name: "",
			times: [],
			editData:{},
		}
		this.columns = [
			{
				title: '序号',
				width: 80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '发布人',
				dataIndex: 'name',
				key: 'name',
				align: 'left',

				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.name}</p>
								<p>{info.user_type == 1 ? "求职者" : "企业HR"}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '内容',
				dataIndex: 'content',
				key: 'content',
				align: 'center',
				width: 200
			}, {
				title: '评论数',
				dataIndex: 'comment_num',
				key: 'comment_num',
				align: 'center'
			}, {
				title: '点赞数',
				dataIndex: 'zan_num',
				key: 'zan_num',
				align: 'center'
			}, {
				title: '图片',
				dataIndex: 'imgs',
				key: 'imgs',
				align: 'center',
				width: 300,
				render: (imgs) => {
					var imgArr = [];
					if (imgs) {
						imgArr = imgs.split(",");
					}
					return (
						<div className='imgsDiv'>
							<Image.PreviewGroup >
								{imgArr.map((img, key) => (
									<Image
										width={40}
										height={40}
										key={key}
										src={img}

									/>
								))}
							</Image.PreviewGroup>
						</div>
					)
				}
			}, {
				title: '状态',
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				render: (status) => {
					if (status == 0) {
						return <p className='color3'>已下架</p>
					} else {
						return <p className='color1'>上架中</p>
					}
				}
			}, {
				title: '发布日期',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center',
				render: (atime) => {
					return atime.substr(0, 10)
				}
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							{item.status == 0 &&
								<Popconfirm
									title="确定要上架该帖子?"
									onConfirm={this.changeStatus.bind(this, item)}

									okText="确定"
									cancelText="取消"
								>
									<p className='color1'>上架</p>
								</Popconfirm>
							}
							{item.status == 1 &&
								<Popconfirm
									title="确定要下架该帖子?"
									onConfirm={this.changeStatus.bind(this, item)}

									okText="确定"
									cancelText="取消"
								>
									<p className='color3'>下架</p>
								</Popconfirm>
							}
							<p className='color1' onClick={()=>{
								this.setState({
									editData: item,
									xqVisible: true,
								})
							}}>查看详情</p>
						</div>
					);
				}
			}
		]
	}
	changeStatus(item) {
		new Promise((resolve) => {
			var req = {
				id: item.id,
				status: item.status == 1 ? 0 : 1
			}
			global.Ajax.hhtc_request('post/enablePost', req).then(res => {
				resolve(null)
				if (res.code != 1) {
					message.error(res.msg)
				} else {
					this.refresh()
				}
			})
		});
	}
	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy: info.orderBy,
			status: this.state.status,
			times: this.state.times
		}
		global.Ajax.hhtc_request('post/postList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(job_id, editStatus, tips) {
		this.setState({
			job_id, editStatus, tips, visible: true
		})
	}

	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入HR或者用户名称查询'
						className='borderbai'
						onChange={(e) => {

							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
					<Select
						allowClear
						className='pubSelt marginr12 borderbai'
						style={{
							width: 150
						}}
						onChange={(e) => {
							console.log(e)
							if (e == undefined) {
								e = -1;
							}
							this.setState({
								status: e
							}, () => {
								this.refresh();
							})
						}}
						placeholder="帖子状态"
						options={[
							{
								value: 1,
								label: '已上架',
							},
							{
								value: 0,
								label: '已下架',
							}
						]}
					/>
					<RangePicker className='borderbai marginr12' onChange={(time, times) => {

						if (times[0] != "") {
							this.setState({
								times
							}, () => {
								this.refresh()
							})
						} else {
							this.setState({
								times: []
							}, () => {
								this.refresh()
							})
						}
					}} />
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>求职圈帖子列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'

					/>
				</div>
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={this.state.tips}
					onCancel={this.onCancel.bind(this)}
				>
					<InputModal job_id={this.state.job_id} status={this.state.editStatus} reqUrl="job/enableJob" onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/* 详情 */}
				<CustomModal
					visible={this.state.xqVisible}
					width={388}
					closable={false}
					className='ginfobox'
					onCancel={this.onCancel.bind(this)}
				>
					<PostDetail data={this.state.editData} />
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
			status: 1,
			job_id: 0,
			xqVisible: false,
			editData:{},
		})
	}
}
