import { Image, message } from 'antd';
import React from 'react';
const edu_level=["","高中及以下","大专","本科","硕士","博士","博士后"];
export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount(){
        global.Ajax.hhtc_request('user/userResumeInfo', {user_id:this.props.data.user_id}).then(res => {
			if(res.code==1){
                this.setState(res.data)
            }else{
                message.errorr(res.msg)
            }
		})
    }
    render() {
        return (
            <div style={{ position: 'relative', paddingTop: 1 }}>
                
                <div className='wrapbox flexColumn'>
              
                    <div className='boxx'>
                   
                        <div className='flexCenter borderbot1'>
                            
                            <div style={{ flex: 1 }}>
                                <h1 className='name'>{this.state.true_name}</h1>
                                <div className='flex bqlist'>
                                    <p className='p1'>{edu_level[this.state.edu_level]}</p>
                                    <p className='p1'>{this.state.expire}</p>
                                </div>
                            </div>
                            <div className='avatar'>
                                <Image
                                    width={60}
                                    height={60}
                                    src={this.state.avatar}
                                />
                                {this.state.sex==1 && 
                                    <div className='sex nan'>
                                        <img alt='' src={require('../../imgs/man.png').default} />
                                    </div>
                                }
                                {this.state.sex==2 && 
                                    <div className='sex'>
                                        <img alt='' src={require('../../imgs/woman.png').default} />
                                    </div>
                                }
                            </div>
                        </div>
                        <p className='t1'>求职种类</p>
                        <div className='borderbot1'>
                            <div className='flwp marginl'>
                                {this.state.job_type_names && this.state.job_type_names.map((item,key)=>(
                                    <p key={key} className='bq'>{item}</p>
                                ))}
                              
                            </div>
                        </div>
                        <p className='t1'>专业领域</p>
                        <div className='borderbot1'>
                            <div className='flwp marginl'>
                                {this.state.area_names && this.state.area_names.map((item,key)=>(
                                    <p key={key} className='bq'>{item}</p>
                                ))}
                              
                            </div>
                        </div>
                        <p className='t1'>个人简介</p>
                        <div className='borderbot1'>
                            <p className='p1 marginl'>{this.state.introduce}</p>
                        </div>
                        <p className='t1'>求职状态</p>
                        <div className='borderbot1'>
                            <p className='p1 marginl'>
                                {this.state.job_status==1 && "离职-随时到岗"}
                                {this.state.job_status==2 && "在职-月内到岗"}
                                {this.state.job_status==3 && "在职-考虑机会"}
                                {this.state.job_status==4 && "在职-暂不考虑"}
                            </p>
                        </div>
                        <p className='t1'>期望薪资</p>
                        <div className='borderbot1'>
                            <p className='p1 marginl'>{this.state.wage_min}k-{this.state.wage_max}k</p>
                        </div>
                       
                        <p className='t1'>求职意向城市</p>
                        <div className='borderbot1'>
                            <p className='p1 marginl'>{this.state.intention_city_name}</p>
                        </div>
                        <p className='t1'>教育经历</p>
                        <div className='borderbot1'>
                            {this.state.eduLogs && this.state.eduLogs.map((item,key)=>(
                                <p key={key} className='p1 marginl'>{item.school_name}·{item.major_name}·{edu_level[item.edu_type]}</p>
                            ))}
                        </div>
                        <p className='t1'>工作经历</p>
                        {this.state.workLogs && this.state.workLogs.map((item,key)=>(
                            <div className='borderbot1'>
                                <div className='flexCenter marginl'>
                                    <p className='t2' style={{ flex: 1 }}>{item.company_name}</p>
                                    <p className='p2'>{item.company_type_name}</p>
                                </div>
                                <div className='flex marginl' style={{ marginBottom: 10 }}>
                                    <p className='p2' style={{ flex: 1 }}>{item.job_type_name}·{item.team_name}</p>
                                    <p className='p2'>{item.stime}-{item.etime}</p>
                                </div>
                                <p className='p1 marginl'>工作内容：{item.work_content}</p>
                                {/* <p className='p1 marginl'>工作业绩：红红火火恍恍惚惚或或或或或或或或</p> */}
                            </div>
                        ))}
                        
                        <p className='t1'>专业技术</p>
                        <div className='borderbot1'>
                            <div className='jnbox'>
                                <div className='flex head'>
                                    <p>技能</p>
                                    <p>掌握程度</p>
                                </div>
                                {this.state.userSkills && this.state.userSkills.map((item,key)=>(
                                    <div className='flex'>
                                        <p>{item.skill_name}</p>
                                        <p>{item.level_name}</p>
                                    </div>
                                ))}
                               
                            </div>
                        </div>
                       
                        <p className='t1'>资格证书</p>
                        <div className='imgbox flex_imgbox'>
                            {this.state.certificates && this.state.certificates.map((row,key)=>(
                                <div key={key}>
                                    <Image
                                        width={70}
                                        height={70}
                                        style={{marginRight:0}}
                                        src={row.img}
                                    />
                                    {/* <p className='cfaDate'>{row.get_date}</p> */}
                                    <p className='cfaDate'>{row.certificate_name}</p>
                                </div>
                            ))}
                        </div>
                       
                        <p className='t1'>个人相册</p>
                        <div className='imgbox'>
                            <Image.PreviewGroup>
                                {this.state.photos && this.state.photos.map((img,key)=>(
                                    <Image
                                        width={70}
                                        height={70}
                                        key={key}
                                        src={img}
                                    />
                                ))}
                            </Image.PreviewGroup>
                        </div>
                    </div>
                </div>
                <img alt='' src={require('../../imgs/shouj.png').default} className='boximg' />
            </div>
        )
    }
}
