import React, { useId } from 'react';
import { DatePicker, Pagination, Modal, message, Select, Image } from 'antd'
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import moment from 'moment';
import CustomModal from '../../common/Modal';
import SelectList from '../../common/SelectList';
import InputModal from './InputModal';
const { RangePicker } = DatePicker;
export default class FeedBack extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
            type:0,//职务类型ID
			name: "",
            times:[]
		}
		this.columns = [
			{
				title: '序号',
				width: 80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: '用户信息',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' >
								<p>{info.name}</p>
                                <p>{info.user_type==1?"求职者":"企业HR"}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '反馈类型',
				dataIndex: 'type_name',
				key: 'type_name',
				align: 'center'
			}, {
				title: '反馈内容',
				dataIndex: 'content',
				key: 'content',
				align: 'center'
			}, {
				title: '图片',
				dataIndex: 'imgs',
				key: 'imgs',
				align: 'center',
                width:300,
                render:(imgs)=>{
                    var imgArr=[];
                    if(imgs){
                        imgArr=imgs.split(",");
                    }
                    return(
                        <div className='imgsDiv'>
                            <Image.PreviewGroup >
                            {imgArr.map((img,key)=>(
                                <Image
                                    width={40}
                                    height={40}
                                    key={key}
                                    src={img}
                                   
                                />
                            ))}
                        </Image.PreviewGroup>
                        </div>
                    )
                }
			}, {
				title: '反馈日期',
				dataIndex: 'atime',
				key: 'atime',
				align: 'center',
				render: (atime, item) => {
					return (
						atime.substr(0,10)
					)
				}
			}
		]
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy: info.orderBy,
			type: this.state.type,
            times:this.state.times
		}
		global.Ajax.hhtc_request('post/feedBackList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(job_id,editStatus,tips) {
		this.setState({
			job_id,editStatus,tips,visible:true
		})
	}
	
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入反馈人名称或者反馈内容查询'
						className='borderbai'
						onChange={(e) => {

							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}

					/>
					<SelectList
						type="feedbackList"
						className='marginr12 borderbai'
                        placeholder="请选择反馈类型"
						style={{ width: 160 }}
						onChange={(role_id) => {
							this.setState({
								type:role_id||0,
							}, () => {
								this.refresh()
							})
						}}
					/>
                    <RangePicker className='borderbai marginr12' onChange={(time,times)=>{
						
						if(times[0]!=""){
							this.setState({
								times
							},()=>{
								this.refresh()
							})
						}else{
							this.setState({
								times:[]
							},()=>{
								this.refresh()
							})
						}
					}} />
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>用户反馈列表列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'

					/>
				</div>
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={this.state.tips}
					onCancel={this.onCancel.bind(this)}
				>
					<InputModal job_id={this.state.job_id} status={this.state.editStatus} reqUrl="job/enableJob" onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)}/>
				</CustomModal>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: '',
            status:1,
            job_id:0
		})
	}
}
