import React from 'react';
import { ConfigProvider } from 'antd'
import {
  Route,
  Switch,
  HashRouter as Router,
  Redirect,
} from 'react-router-dom';
import './App.css'
import './reset.css'

import Rout from './component/Router';  //路由
import Login from './component/Auth/Login';  //登录

import Ajax from './class/Ajax';
global.Ajax=new Ajax();


export default class App extends React.Component {
	constructor(props){
		super(props)
		this.state={
			
		}
	}
	componentDidMount(){
		var mergedNextColor=JSON.parse(localStorage.getItem('honghuTheme'))
        ConfigProvider.config({
            theme: mergedNextColor,
		});
	}
	render(){
		return(
			<React.Fragment>
				<Router>
					<Switch>
						<Route  path="/rout" component={Rout} />
						<Route  path="/login" component={Login} />

						{/* 默认访问 */}
						<Redirect to='/login'/>
					</Switch>
				</Router>
			</React.Fragment>
		)
	}
}
