import React from 'react';
import { Button, Image, Tooltip, Switch, Form, Input, Select, message } from 'antd';
import Helper from '../../class/Helper';
import CustomTable from '../../common/Table';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';
import UploadList from '../../common/UploadList';
import Ueditor from '../../common/Ueditor';
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import MyEditor from '../../common/MyEditor';

export default class Index extends React.Component {
	constructor(props) {
		super(props)
		this.state = {

		}
		this.columns = [{
			title: '序号',
			dataIndex: 'key',
			key: 'key',
			align: 'center',
			width: 60,
		}, {
			title: '轮播图',
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: url => (
				<Image src={url} width={40} height={40} />
			)
		}, {
			title: '轮播图标题',
			dataIndex: 'title',
			key: 'title',
			align: 'center',
			ellipsis: {
				showTitle: false,
			},
		}, {
			title: '详细内容',
			dataIndex: 'content',
			key: 'content',
			align: 'center',
			ellipsis: {
				showTitle: false,
			},
			render: content => (
				<Tooltip placement='topLeft' title={Helper.getValue(content)}>
					{Helper.getValue(content) || '-'}
				</Tooltip>
			)
		}, {
			title: '阅读量',
			dataIndex: 'read_num',
			key: 'read_num',
			align: 'center',
		}, {
			title: '是否显示',
			dataIndex: 'display',
			key: 'display',
			align: 'center',
			render: (display, item, index) => (
				<Switch readOnly checked={display == 1 ? true : false} checkedChildren='显示' unCheckedChildren='隐藏' onChange={this.changeStatus.bind(this, item, index)} />
			)
		}, {
			title: '操作',
			dataIndex: 'id',
			key: 'id',
			align: 'center',
			render: (id, item) => {
				return (
					<div className='flexAllCenter pubbtnbox'>
						{item.content && <p onClick={this.changeVisible.bind(this, 'visible', item)}>详情</p>}
						<p onClick={this.edit.bind(this, item)}>编辑</p>
						<p onClick={this.del.bind(this, item)}>删除</p>
					</div>
				)
			}
		}]
	}
	changeStatus() { }
	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
		}
		global.Ajax.hhtc_request('config/banner', req).then(res => {
			callback(res)
		})
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	changeVisible(key, data) {
		this.setState({
			[key]: true,
			editData: data,
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: data,
			type: 'edit',
			addVisible: true,
			pic: data.url,
			jumpValue: data.name,
		})
		setTimeout(() => {
			let req = {
				url: data.url,
				title: data.title,
				type: data.type,
				content: data.content,
				display: data.display == 1 ? true : false,
				sort: data.sort || undefined,
			}
			if (data.type == 3) {
				setTimeout(() => {
					this.ueditor.setContent(data.content)
				}, 100);
			} else {
			}
			this.formRef.setFieldsValue(req)
		}, 100);
	}
	// 删除
	del(data) {
		var url = 'config/delBanner', req = { id: data.id }
		this.delmask.del(url, req)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Button type='primary' onClick={this.changeVisible.bind(this, 'addVisible')}>添加轮播图</Button>
				</div>
				<div className='bgbai basiclistt'>
					<h2 className='pubTit'>轮播图列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'
					/>
				</div>
				{/* 添加轮播图 */}
				<CustomModal
					visible={this.state.addVisible}
					width={800}
					title={`${this.state.type == 'edit' ? '编辑' : '添加'}轮播图`}
					onCancel={this.onCancel.bind(this)}
				>
					<div className='ovau'>
						<Form
							ref={dom => this.formRef = dom}
							onFinish={this.onFinish}
							labelCol={{ flex: '96px' }}
							onValuesChange={(res) => {
								let key = (Object.keys(res))[0];
								if (key == 'type') {
									// this.setState({
									// 	bannerType: res[key],
									// 	jumpValue: ''
									// })
									this.formRef.setFieldsValue({
										content: undefined,
									})
								}
							}}
							initialValues={{
								display: true,
							}}
						>
							<Form.Item label='轮播图片' name='url' rules={[{ required: true, message: '请上传轮播图！' }]}>
								<div className='takbox' onClick={() => {
									if(this.state.type=='edit'){
										return
									}
									this.uplist.setState({
										visible: true,
									})
								}}>
									{this.state.pic ?
										<img alt='' src={this.state.pic} width={118} height={118} />
										:
										<React.Fragment>
											<span className='iconfont icon-xiangji'></span>
											<p>选择图片</p>
											<span>推荐尺寸比例为16:9</span>
										</React.Fragment>
									}
								</div>
								<Input bordered={false} style={{ display: 'none' }} />
							</Form.Item>
							<Form.Item label='轮播图名称' name='title' rules={[{ required: true, message: '请输入轮播图名称' }]}>
								<Input autoComplete='off' placeholder='请输入轮播图名称' readOnly={this.state.type=='edit'?true:false} />
							</Form.Item>
							<Form.Item label='轮播图类型' name='type' rules={[{ required: true, message: '请选择轮播图类型！' }]}>
								<Select placeholder='请选择轮播图类型' allowClear options={[
									{ label: '公司详情', value: 1 },
									{ label: '职务详情', value: 2 },
									{ label: '打开详情', value: 3 },
								]} disabled={this.state.type=='edit'?true:false}></Select>
							</Form.Item>
							<Form.Item noStyle shouldUpdate={(prev, cur) => prev.type != cur.type}>
								{({ getFieldValue }) => (
									getFieldValue('type') && <Form.Item label='内容' name='content'>
										{getFieldValue('type') == 3 ?
											<>
												<Input style={{ display: 'none' }} />
												{/* <Ueditor ref={e => this.ueditor = e} className="myarea" style={{ height: 200, flex: 1, border: '1px solid #d9d9d9' }} /> */}
												<MyEditor
													ref={e => this.ueditor = e}
													readOnly={this.state.type=='edit'?true:false}
													className="myarea"
													style={{ height: 200, flex: 1, }}
													changeValue={(e) => {
														this.formRef && this.formRef.setFieldsValue({
															content: e,
														})
													}}
												/>
											</>
											:
											<Input autoComplete='off' placeholder={`请输入${getFieldValue('type') == 1 ? '公司' : '职务'}id`} />
										}
									</Form.Item>
								)}
							</Form.Item>
							<Form.Item label='排序' name='sort'>
								<Input autoComplete='off' placeholder='排序越小越靠前' />
							</Form.Item>
							<Form.Item label='是否显示' name='display' valuePropName='checked'>
								<Switch checkedChildren='显示' unCheckedChildren='隐藏' />
							</Form.Item>
							<Button loading={this.state.loading} htmlType='submit' type="primary" style={{ display: 'block', marginLeft: 'auto', marginTop: 10 }}>确定</Button>
						</Form>
					</div>
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.refresh()
				}} />
				{/* 文件库 */}
				<UploadList fileNum={1} ref={dom => this.uplist = dom} type={1} onOk={this.onUploadOk.bind(this)} />
			</React.Fragment>
		)
	}
	onFinish = (req) => {
		this.setState({
			loading: true,
		}, () => {
			req.display = req.display ? 1 : 0;
			let url = 'config/addBanner';
			if (this.state.type == 'edit') {
				url = 'config/editBanner';
				req.id = this.state.editData.id;
			}
			global.Ajax.hhtc_request(url, req).then(res => {
				if (res.code == 1) {
					message.success(res.msg, 1.2)
					this.onCancel()
					this.refresh()
				} else {
					message.error(res.msg, 1.2)
				}
				this.setState({ loading: false, })
			})
		})
	}
	onUploadOk(data) {
		this.setState({
			pic: data[0]
		})
		this.formRef.setFieldsValue({
			url: data[0]
		})
		this.uplist.onCancel()
	}
	onCancel() {
		this.setState({
			addVisible: false,
			pic: null,
			type: ''
		})
	}
}
