import React, { useId } from 'react';
import { Button, Pagination, Modal, message, Select, Image, Form, Input, Switch } from 'antd'
import CustomTable from '../../common/Table';
import CustomModal from '../../common/Modal';
import SelectList from '../../common/SelectList';
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗

export default class JobList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            job_type_id: 0,//职务类型ID
            name: "",
            status: 0,
            job_id: 0,
            tips: "",
            editStatus: 0
        }
        this.columns = [{
            title: 'Vip等级',
            dataIndex: 'vip_level',
            key: 'vip_level',
            align: 'center'
        }, {
            title: 'Vip等级名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        }, {
            title: 'Vip价格',
            dataIndex: 'vip_price',
            key: 'vip_price',
            align: 'center',
            render: price=>`${price || '-'}`
        }, {
            title: '请求简历次数',
            dataIndex: 'resume_num',
            key: 'resume_num',
            align: 'center',
            render: num => `${num}次/日`
        }, {
            title: '主动沟通次数',
            dataIndex: 'contact_num',
            key: 'contact_num',
            align: 'center',
            render: num => `${num}次/日`
        }, {
            title: '保存简历份数',
            dataIndex: 'send_email',
            key: 'send_email',
            align: 'center',
            render: num => `${num}份/日`
        }, {
            title: '展示招聘数据',
            dataIndex: 'show_data',
            key: 'show_data',
            align: 'center',
            render: data => `${data == 1 ? '展示' : '隐藏'}`
        }, {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (id, item) => {
                return (
                    <div className='flexAllCenter pubbtnbox'>
                        <p onClick={this.edit.bind(this, item)}>编辑</p>
                        {/* <p onClick={this.del.bind(this, item)}>删除</p> */}
                    </div>
                )
            }
        }
        ]
    }
    refresh() {
        this.tabblelist.onRefresh()
    }
    getList(info, callback) {
        var req = {
            page: info.page,
            size: info.size,
            orderBy: info.orderBy,
        }
        global.Ajax.hhtc_request('company/vipConfigList', req).then(res => {
            callback(res)
        })
    }
    // 编辑
    edit(data) {
        this.setState({
            type: 'edit',
            editData: data,
            visible: true
        }, () => {
            setTimeout(() => {
                this.formRef.setFieldsValue(data)
            }, 100);
        })
    }
    // 删除
    del(data) {
        var url = 'company/delVipConfig', req = { id: data.id }
        this.delmask.del(url, req, "确定要删除此模板吗?")
    }
    onRefresh(info, callback) {
        this.getList(info, callback)
    }
    render() {
        return (
            <React.Fragment>
                {/* <div className='flexCenter'>
                    <Button type='primary' onClick={() => {
                        this.setState({
                            type: 'add',
                            visible: true,
                        })
                    }}>新增配置</Button>
                </div> */}
                <div className='bgbai'>
                    <h2 className='pubTit'>VIP配置</h2>
                    <CustomTable
                        ref={dom => this.tabblelist = dom}
                        columns={this.columns}
                        onRefresh={this.onRefresh.bind(this)}  //刷新
                        className='pubList'
                    />
                </div>
                <CustomModal
                    visible={this.state.visible}
                    width={800}
                    title={this.state.type == 'edit' ? '编辑' : '新增'}
                    onCancel={this.onCancel.bind(this)}
                >
                    <Form
                        onFinish={this.onFinish}
                        ref={dom => this.formRef = dom}
                        labelCol={{ flex: '112px' }}
                        onValuesChange={this.onValuesChange}
                        labelWrap
                        initialValues={{
                            show_data: false,
                        }}
                    >
                        <div className='flwp'>
                            <Form.Item className='item48' label='Vip等级名称' name='name' rules={[{ required: true, message: '请输入Vip等级名称' }]}>
                                <Input autoComplete='off' placeholder='请输入Vip等级名称' />
                            </Form.Item>
                            <Form.Item className='item48' label='每日主动沟通次数' name='contact_num' rules={[{ required: true, message: '请输入每日主动沟通次数' }]} labelCol={{ flex: '167px' }}>
                                <Input autoComplete='off' placeholder='请输入每日主动沟通次数' />
                            </Form.Item>
                            <Form.Item className='item48' label='Vip等级' name='vip_level' rules={[{ required: true, message: '请输入Vip等级' }]}>
                                <Input autoComplete='off' placeholder='请输入Vip等级' />
                            </Form.Item>
                            <Form.Item className='item48' label='每日主动请求简历次数' name='resume_num' rules={[{ required: true, message: '请输入每日主动请求简历次数' }]} labelCol={{ flex: '167px' }}>
                                <Input autoComplete='off' placeholder='请输入每日主动请求简历次数' />
                            </Form.Item>
                            <Form.Item className='item48' label='Vip价格' name='vip_price' rules={[{ required: true, message: '请输入价格' }]}>
                                <Input autoComplete='off' placeholder='请输入价格' />
                            </Form.Item>
                            <Form.Item className='item48' label='是否展示招聘数据' name='show_data' valuePropName='checked' rules={[{ required: true }]} labelCol={{ flex: '167px' }}>
                                <Switch checkedChildren='展示' unCheckedChildren='隐藏' />
                            </Form.Item>
                            <Form.Item className='item48' label='保存简历份数' name='send_email' rules={[{ required: true, message: '请输入每日保存简历份数' }]}>
                                <Input autoComplete='off' placeholder='请输入每日保存简历份数' />
                            </Form.Item>
                        </div>
                        <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit">确定</Button>
                    </Form>
                </CustomModal>
                {/* 删除弹窗 */}
                <Del ref={dom => this.delmask = dom} onRefresh={() => {
                    this.refresh()
                }} />
            </React.Fragment>
        )
    }
    onValuesChange = (res) => {
        var key = Object.keys(res)[0];
        if (key == 'contact_num' || key == 'vip_level' || key == 'send_file_num' || key == 'search_weight') {
            var value = Helper.getNumber(res[key]);
            this.formRef.setFieldsValue({
                [key]: value,
            })
        } else if (key == 'vip_price') {
            var value = Helper.getFloat(res[key]);
            this.formRef.setFieldsValue({
                [key]: value,
            })
        }
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        }, () => {
            req.show_data = req.show_data ? 1 : 0;
            let url = 'company/addVipConfig';
            if (this.state.type == 'edit') {
                url = 'company/editVipConfig';
                req.id = this.state.editData.id;
            }
            global.Ajax.hhtc_request(url, req).then(res => {
                if (res.code == 1) {
                    message.success(res.msg, 1.2);
                    this.onCancel()
                    this.refresh()
                } else {
                    message.error(res.msg, 1.2)
                }
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 1500);
            })
        })
    }
    onOk() {
        this.refresh()
        this.onCancel()
    }
    onCancel() {
        this.setState({
            visible: false,
            type: null,
        })
    }
}
