import React from 'react';
import {Input, Button, Form, message, Select} from 'antd';
import SelectList from '../../common/SelectList'

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            is_team_leader:0,
            is_company_leader:0,
            team_users:[],
            company_users:[]
        }
    }
    componentDidMount() {
        //获取需要删除的信息
        global.Ajax.hhtc_request("company/beforeDelhR", {id:this.props.data.c_uid}).then(res=>{
            if(res.code==1){
                this.setState(res.data)
            }else{

            }

        })
    }

    checkPwd=(value)=>{
        if (this.props.type != 'edit') {
            if(this.state.pwd==''){
                return Promise.reject(new Error('登录密码不能为空!'));
            }
        }
        return Promise.resolve();
    }
    onFinish = (req) => {
        this.setState({
            loading: true,
        },()=>{
            var url='company/delHr';
            req.id=this.props.data.c_uid
            global.Ajax.hhtc_request(url,req).then(res=>{
                if(res.code==1){
                    message.success(res.msg,1.2)
                    this.props.onOk()
                }else{
                    message.error(res.msg,1.2)
                }
                this.setState({
                    loading: false,
                })
            })
        })
    }
    render() {
        return (
            <div className='editPwdBox'>
                <Form
                    onFinish={this.onFinish}
                    labelCol={{span: 8}}
                    labelAlign="left"
                    initialValues={{
                        username: this.props.data.name || undefined,
                    }}
                >
                    <Form.Item name="username" label="HR：" rules={[{ required: true, message: '请输入用户昵称!' }]}>
                        <Input disabled value={this.props.data.name} autoComplete="off" placeholder='请设置6-12位用户昵称' type='text' />
                    </Form.Item>
                    {this.state.is_team_leader==1 &&
                    <Form.Item  name="team_u_id" label="团队接替人：" rules={[{ required: true, message: '请选择团队接替人!' }]}>
                        <Select
                            placeholder='请选择团队接替人'
                            suffixIcon={(<span className='iconfont icon-xia'></span>)}
                            allowClear

                            className={`pubSelt ${this.props.className}`}
                        >
                            {this.state.team_users.length>0?this.state.team_users.map((item, index) => (
                                <Select.Option value={item.id*1} key={''+index}>{item.name}</Select.Option>
                            )):
                                <Select.Option value={0} >删除团队</Select.Option>
                            }
                        </Select>
                    </Form.Item>

                    }
                    {this.state.is_company_leader==1 &&
                    <Form.Item  name="company_u_id" label="企业接替人：" rules={[{ required: true, message: '请选择企业接替人!' }]}>
                        <Select
                            placeholder='请选择企业接替人'
                            suffixIcon={(<span className='iconfont icon-xia'></span>)}
                            allowClear

                            className={`pubSelt ${this.props.className}`}
                        >
                            {this.state.company_users.length>0?this.state.company_users.map((item, index) => (
                                    <Select.Option value={item.id*1} key={''+index}>{item.name}</Select.Option>
                                )):
                                <Select.Option value={0} >删除企业</Select.Option>
                            }
                        </Select>
                    </Form.Item>

                    }
                    <Button style={{ display: 'block', marginLeft: 'auto' }} loading={this.state.loading} type="primary" htmlType="submit" className="dlbtn">确定</Button>
                </Form>
            </div>
        )
    }
}
