import React from 'react';
import { Button, Pagination, Modal, Switch, message, Spin, Empty } from 'antd'
import EditMenu from './EditMenu';  //添加、编辑菜单
import Helper from '../../class/Helper';
import Del from '../../common/Del';  //删除弹窗
import CustomModal from '../../common/Modal';

export default class Index extends React.Component {
	constructor(props) {

		super(props)
		this.state = {
			total: 0,
			size: 10,
			page: 1,
			list: [
				// {
				// 	className: 'icon-jibenguanli',
				// 	name: '基本管理',
				// 	open: false,
				// 	child: [
				// 		{
				// 			className: 'icon-wenjian', name: '角色列表',
				// 			open: false,
				// 			child: [{ name: '编辑角色', className: 'icon-wenjian' }]
				// 		}
				// 	]
				// },
				// {
				// 	className: 'icon-xitongshezhi',
				// 	name: '系统设置',
				// 	open: false,
				// 	child: [
				// 		{ className: 'icon-wenjian', name: '菜单管理', open: false, child: [{ className: 'icon-wenjian', name: '添加菜单' }, { className: 'icon-wenjian', name: '编辑菜单' }] },
				// 		{ className: 'icon-wenjian', name: '上传设置', open: false, child: [] },
				// 	]
				// }
			],
			visible: false,
			level: null,
			pid: 0,
			fid: 0,
			sid: 0,
			data: {},
			type: '',
			loading: false,
			orderBy: '',
			requestLoadingShow: true,
		}
	}
	componentDidMount() {
		this.getList()
	}
	refresh(){
		this.getList()
	}
	open(index) {
		this.state.list[index].open = !this.state.list[index].open;
		this.setState({
			list: this.state.list,
		})
	}
	openSec(index, k) {
		this.state.list[index].child[k].open = !this.state.list[index].child[k].open
		this.setState({
			list: this.state.list,
		})
	}
	changeVisible(key, level, fid, sid) {
		this.setState({
			[key]: true,
			level,
			pid: fid,
			sid,
		})
	}
	getList() {
		this.setState({
			requestLoadingShow: true,
			list: []
		}, () => {
			var orderBy = this.state.orderBy;
			var req = {
				page: this.state.page,
				size: this.state.size,
				orderBy: orderBy
			}
			global.Ajax.hhtc_request('menu/menuList', req).then(res => {
				if (res.code == 1) {
					this.setState({
						total: res.data.all,
						list: res.data.datas,
					})
				}
				this.setState({
					requestLoadingShow: false,
				})
			})
		})
	}
	getNum(index) {
		var num = index * this.state.page + 1;
		if (num < 9) {
			num = '0' + num;
		}
		return num
	}
	edit(data, pid, mid) {
		data.pid = pid;
		var fid, sid;
		if (data.level == 3) {
			sid = mid;
			fid = pid;
		} else if (data.level == 2) {
			fid = pid;
		}
		this.setState({
			visible: true,
			data,
			type: 'edit',
			level: data.level,
			fid,
			sid,
			pid,
		})
	}
	// 删除菜单
	del(data) {
		var url = 'menu/delMenu', req = { id: data.id }
		this.delmask.del(url, req)
	}
	// 打开日志
	setNeedLog(data, findex, sindex, tindex) {
		var list = this.state.list;
		this.setState({
			loading: data.id,
		}, () => {
			var req = {
				id: data.id,
				needLog: data.needLog == 1 ? 0 : 1,
			}
			global.Ajax.hhtc_request('menu/setNeedLog', req).then(res => {
				if (res.code == 1) {
					if (data.level == 1) {  //一级
						list[findex].needLog = data.needLog == 1 ? 0 : 1
					} else if (data.level == 2) {  //二级
						list[findex].child[sindex].needLog = data.needLog == 1 ? 0 : 1
					} else if (data.level == 3) {  //三级
						list[findex].child[sindex].child[tindex].needLog = data.needLog == 1 ? 0 : 1
					}
					this.setState({ list })
				} else {
					message.error(res.msg, 1.2)
				}
				this.setState({
					loading: false
				})
			})
		})
	}
	render() {
		const pubText = (data, type, fid, sid, findex, sindex, tindex) => {
			var req = (
				<React.Fragment>
					<p className='rowFlex'>{data.path || '-'}</p>
					<p className='rowFlex'>{data.route || '-'}</p>
					<p className='row15'>
						<Button type='primary' className={`${type == 2 && 'fu1'} ${type == 3 && 'fu2'}`}>{type == 1 && '一'}{type == 2 && '二'}{type == 3 && '三'}级菜单</Button>
					</p>
					<p className='rowFlex'>
						<Switch loading={this.state.loading == data.id ? true : false} checkedChildren="开启" unCheckedChildren="关闭" checked={data.needLog == 1 ? true : false} onClick={this.setNeedLog.bind(this, data, findex, sindex, tindex)} />
					</p>
					<p className='rowFlex'>
						{data.display == 1 ?
							<Button type='primary'>显示</Button>
							:
							<Button className='pubHui' disabled={data.display == 1 ? false : true}>隐藏</Button>
						}
					</p>
					<div className='row2 flexAllCenter pubbtnbox'>
						{type != 3 && <p onClick={this.changeVisible.bind(this, 'visible', type + 1, fid, sid)}>添加子菜单</p>}
						<p onClick={this.edit.bind(this, data, fid, sid)}>编辑</p>
						<p onClick={this.del.bind(this, data)}>删除</p>
						{/* {type != 3 && <Button
							type='primary'
							icon={(<span className='iconfont icon-tianjia marginr4'></span>)}
							className='fu2 marginr8'
							onClick={this.changeVisible.bind(this, 'visible', type + 1, fid, sid)}
						>添加子菜单</Button>}
						<Button
							type='primary'
							icon={(<span className='iconfont icon-bianji marginr4'></span>)}
							onClick={this.edit.bind(this, data, fid, sid)}
						>编辑</Button>
						<Button
							type='primary'
							icon={(<span className='iconfont icon-shanchu marginr4'></span>)}
							className='fu1 marginl8'
							onClick={this.del.bind(this, data)}
						>删除</Button> */}
					</div>
				</React.Fragment>
			)
			return req;
		}
		return (
			<React.Fragment>
				<Button type='primary' onClick={this.changeVisible.bind(this, 'visible', 1, 0, '')}>添加菜单</Button>
				<div className='bgbai'>
					<h2 className='pubTit'>菜单列表</h2>
					<div className='pubList menuListbox customScrollbar'>
						<div className='head flex'>
							<p className='flexAllCenter cursor' style={{ width: 80 }}>
								序号<span className={`iconfont jt ${this.state.orderBy == 'desc' ? 'down' : ''} ${this.state.orderBy == 'asc' ? 'up' : ''}`} onClick={() => {
									var orderBy = this.state.orderBy;
									if (orderBy == 'desc') {
										orderBy = 'asc'
									} else {
										orderBy = 'desc'
									}
									this.setState({
										orderBy
									}, () => {
										this.getList()
									})
								}}></span>
							</p>
							<p className='row2'>菜单名称</p>
							<p className='rowFlex'>前端路由</p>
							<p className='rowFlex'>后端路由</p>
							<p className='row15'>菜单等级</p>
							<p className='rowFlex'>打印日志</p>
							<p className='rowFlex'>显示状态</p>
							<p className='row2'>操作</p>
						</div>
						{this.state.requestLoadingShow && <Spin tip="加载中..." style={{ display: 'block', margin: '100px auto 0' }} />}
						{this.state.total == 0 && !this.state.requestLoadingShow && <Empty style={{ marginTop: 100 }} />}
						{this.state.list.map((item, index) => {
							return (
								<div className='fir' key={'' + index}>
									<div className='flex'>
										<p style={{ width: 80 }}>{Helper.getNum(index, this.state.total, this.state.size, this.state.page, this.state.orderBy)}</p>
										<div className='row2 flexCenter paddleft98'>
											{item.child && item.child.length > 0 && <React.Fragment>
												<span id={index} className={`iconfont icon-jiantou-shang sjx ${item.open ? 'xia' : ''}`} onClick={this.open.bind(this, index)}></span>
											</React.Fragment>}
											<span className={`iconfont ${item.icon}`}></span>
											<span className='zii'>{item.name}</span>
										</div>
										{pubText(item, 1, item.id, '', index, '', '')}
									</div>
									{item.child && <React.Fragment>
										{item.child.map((row, k) => (
											<div className={`sec ${item.open ? 'open' : ''}`} ref={dom => this[`sec${index}`] = dom} key={'' + k} style={{ height: row.open && item.open && (row.child.length + 1) * 60 }}>
												<div className='flex'>
													<p style={{ width: 80, marginLeft: 20 }}>
														{Helper.getNum(k, item.child.length, item.child.length, 1, this.state.orderBy)}
													</p>
													<div className='row2 flexCenter paddleft98'>
														{row.child && row.child.length > 0 && <React.Fragment>
															<span className={`iconfont icon-jiantou-shang sjx ${row.open ? 'xia' : ''}`} style={{ marginLeft: 32 }} onClick={this.openSec.bind(this, index, k)}></span>
														</React.Fragment>}
														<span className={`iconfont icon-wenjian`} style={{ marginLeft: row.child.length == 0 ? 32 : '' }}></span>
														<span className='zii'>{row.name}</span>
													</div>
													{pubText(row, 2, item.id, row.id, index, k, '')}
												</div>
												{row.child && <div className={`three ${row.open ? 'open' : ''}`} style={{ height: row.open ? row.child.length * 60 : '0' }}>
													{row.child.map((res, i) => (
														<div className='flex' key={'' + i}>
															<p style={{ width: 80, marginLeft: 40 }}>
																{Helper.getNum(i, row.child.length, row.child.length, 1, this.state.orderBy)}
															</p>
															<div className='row2 flexCenter paddleft98'>
																<span className={`iconfont icon-wenjian`} style={{ marginLeft: 48 }}></span>
																<span className='zii'>{res.name}</span>
															</div>
															{pubText(res, 3, item.id, row.id, index, k, i)}
														</div>
													))}
												</div>}
											</div>
										))}
									</React.Fragment>}
								</div>
							)
						})}

					</div>
					{this.state.total > 0 && <Pagination
						total={this.state.total}
						pageSize={this.state.size}
						showTotal={(total, range) => {
							return `共${total}条记录，本页展示${range[0]}-${range[1]}条记录`
						}}
						onChange={(page) => {
							this.setState({
								page,
							}, () => {
								this.getList()
							})
						}}
					/>}
				</div>
				{/* 添加、编辑菜单 */}
				<CustomModal
					visible={this.state.visible}
					width={360}
					title={`${this.state.type == 'edit' ? '编辑' : '添加'}菜单`}
					onCancel={this.onCancel.bind(this)}
				>
					<EditMenu
						onCancel={this.onCancel.bind(this)}
						onOk={this.onOk.bind(this)}
						level={this.state.level}
						pid={this.state.pid}
						fid={this.state.fid}
						sid={this.state.sid}
						data={this.state.data}
						type={this.state.type}
					/>
				</CustomModal>
				{/* 删除弹窗 */}
				<Del ref={dom => this.delmask = dom} onRefresh={() => {
					this.getList()
				}} />
			</React.Fragment>
		)
	}
	onOk() {
		this.onCancel()
		this.getList()
	}
	onCancel() {
		this.setState({
			visible: false,
			type: ''
		})
	}
}
