import React, { useId } from 'react';
import { Button, Pagination, Modal, message, Select, Image } from 'antd'
import SelectList from '../../common/SelectList';
import Input from '../../common/Input';
import CustomTable from '../../common/Table';
import moment from 'moment';
import CustomModal from '../../common/Modal';
import HRInfo from './HRInfo';  // hr信息
import DelHr from "./DelHr";//删除hr
import UserCharge from '../user/UserRecharge';
import Refuse from '../../common/Refuse';  //拒绝弹窗

export default class CompanyUser extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			status: -1,
			name: "",
			type: '',
			rechargeVisible: false,
			editData: {},
			delVisible:false
		}
		this.columns = [
			{
				title: '序号',
				width: 80,
				dataIndex: 'key',
				key: 'key',
				sorter: true,
				align: 'center'
			}, {
				title: 'HR信息',
				dataIndex: 'name',
				key: 'name',
				align: 'left',
				width: 300,
				className: 'pubname',
				render: (_, info) => {
					return (
						<div className='userInfo'>
							<Image src={info.avatar} className='avatar' width={40} height={40} />
							<div className='userNameInfo' onClick={() => {
								this.setState({
									type: 'hr',
									editData: info,
									visible: true,
								})
							}}>
								<p>{info.name}</p>
								<p>{info.job_name}·{info.mobile}</p>
							</div>
						</div>
					);
				}
			}, {
				title: '团队信息',
				dataIndex: 'user_type',
				key: 'user_type',
				align: 'left',
				width: 200,
				className: 'pubname',
				render: (_, info) => {
					return (
						<>
							<p onClick={() => {
								this.setState({
									editData: info,
									type: 'business',
									visible: true,
								})
							}}>{info.company_name}</p>
							<p onClick={() => {
								this.setState({
									editData: info,
									type: 'team',
									visible: true,
								})
							}}>{info.team_name}</p>
						</>
					);
				}
			}, {
				title: '微信号',
				dataIndex: 'wx_no',
				key: 'wx_no',
				align: 'center',

			}, {
				title: '邮箱',
				dataIndex: 'email',
				key: 'email',
				align: 'center'
			}, {
				title: 'VIP',
				dataIndex: 'vip_level',
				key: 'vip_level',
				align: 'center',
				render: (is_vip, item) => {
					if (is_vip > 1) {
						var time = item.vip_expire_time - parseInt(moment().valueOf() / 1000);
						var dayTime = 3600 * 24;
						var day = Math.ceil((time / dayTime))
						var info = "";
						if (day < 1) {
							info = "一天内到期";
						} else {
							info = "剩余" + day + "天";
						}
						return (<p className='color1'>{info}</p>);
					} else {
						return "非VIP";
					}
				}
			}, {
				title: '状态',
				dataIndex: 'status',
				key: 'status',
				align: 'center',
				className: 'cursor',
				render: (status, item) => {
					if (status == 1) {
						return <p onClick={() => {
							Modal.confirm({
								title: "提示",
								content: "是否审核通过?",
								cancelText: "驳回",
								okText: "通过",
								centered: true,
								onCancel: () => {
									// this.refe.setData({
									// 	visible: true,
									// 	id: item.c_uid,
									// 	status: 2,
									// })
									this.refe.setState({
										visible: true,
										id: item.c_uid,
										status: 3,
									})
								},
								onOk: () => {
									this.checkStatus(2, item.c_uid)
								},

							})
						}} className='color2'>待审核</p>
					} else if (status == 2) {
						return <p onClick={() => {
							Modal.confirm({
								title: "提示",
								content: "是否停用此账号?",
								cancelText: "取消",
								okText: "停用",
								centered: true,
								onCancel: () => { },
								onOk: () => {
									this.refe.setState({
										visible: true,
										id: item.c_uid,
										status: 4,
									})
								},

							})
						}} className='color1'>正常</p>
					} else if (status == 3) {
						return <p className='color3'>已拒绝</p>
					} else {
						return <p onClick={() => {
							Modal.confirm({
								title: "提示",
								content: "是否解禁此账号?",
								cancelText: "取消",
								okText: "解禁",
								centered: true,
								onCancel: () => { },
								onOk: () => {
									this.refe.setState({
										visible: true,
										id: item.c_uid,
										status: 2,
									})
								},

							})
						}}


						className='color4'>账号禁用</p>
					}
				}
			}, {
				title: '审核备注',
				dataIndex: 'remarks',
				key: 'remarks',
				align: 'center',
				render: (remarks) => (
					remarks || "-"
				)
			}, {
				title: '操作',
				dataIndex: 'admin_id',
				key: 'admin_id',
				align: 'center',
				render: (id, item) => {
					return (
						<div className='flexAllCenter pubbtnbox'>
							<p className='color2' onClick={this.edit.bind(this, item)}>充值VIP</p>
							<p className='color1' onClick={() => {
								this.setState({
									editData: item,
									type: 'hr',
									visible: true,
								})
							}}>查看详情</p>
							<p className='color3' onClick={() => {
								this.setState({
									editData: item,
									type: 'hr',
									delVisible: true,
								})
							}}>删除</p>
						</div>
					)
				}
			}
		]
	}
	checkStatus(status, c_uid, remarks) {
		let req = {
			status,
			c_uid,
			remarks,
		}
		global.Ajax.hhtc_request('company/companyUserEnable', req).then(res => {
			if (res.code == 1) {
				message.success(res.msg, 1.2)
				this.refresh()
			} else {
				message.error(res.msg, 1.2)
			}
		})
	}

	refresh() {
		this.tabblelist.onRefresh()
	}
	getList(info, callback) {
		var req = {
			page: info.page,
			size: info.size,
			name: this.state.name,
			orderBy: info.orderBy,
			status: this.state.status
		}
		global.Ajax.hhtc_request('company/companyUserList', req).then(res => {
			callback(res)
		})
	}
	// 编辑
	edit(data) {
		this.setState({
			editData: {
				user_id: data.c_uid,
				nickname: data.name
			},
			rechargeVisible: true
		})
	}
	// 删除
	del(data) {
		var url = 'user/userEnable', req = { user_id: data.user_id }
		this.delmask.del(url, req, "确定要" + (data.status == 1 ? "冻结" : "解冻") + "用户吗?")
	}
	onRefresh(info, callback) {
		this.getList(info, callback)
	}
	render() {
		return (
			<React.Fragment>
				<div className='flexCenter'>
					<Input
						placeholder='请输入团队名称、公司名称、HR名称查询'
						className='borderbai'
						style={{ width: 340 }}
						onChange={(e) => {
							this.setState({
								name: e.target.value
							}, () => {
								this.refresh()
							})
						}}
					/>
					<Select
						allowClear
						className='pubSelt marginr12 borderbai'
						style={{
							width: 150
						}}
						onChange={(e) => {
							if (e == undefined) {
								e = -1;
							}
							this.setState({
								status: e
							}, () => {
								this.refresh();
							})
						}}
						placeholder="用户状态"
						options={[
							{
								value: 1,
								label: '待审核',
							},
							{
								value: 2,
								label: '正常',
							},
							{
								value: 3,
								label: '已拒绝',
							},
							{
								value: 4,
								label: '账号停用',
							}
						]}
					/>
				</div>
				<div className='bgbai'>
					<h2 className='pubTit'>企业用户列表</h2>
					<CustomTable
						ref={dom => this.tabblelist = dom}
						columns={this.columns}
						onRefresh={this.onRefresh.bind(this)}  //刷新
						className='pubList'

					/>
				</div>
				{/* 充值VIP */}
				<CustomModal
					visible={this.state.rechargeVisible}
					width={360}
					title={"充值VIP"}
					onCancel={this.onCancel.bind(this)}
				>
					<UserCharge data={this.state.editData} reqUrl={"company/rechargeVip"} type={2} onCancel={this.onCancel.bind(this)} onOk={this.onOk.bind(this)} />
				</CustomModal>
				{/*
					type=hr  查看hr信息
					type=business  查看企业信息
					type=team  查看团队信息
				 */}
				<CustomModal
					visible={this.state.visible}
					width={388}
					closable={false}
					className='ginfobox'
					onCancel={this.onCancel.bind(this)}
				>
					<HRInfo data={this.state.editData} type={this.state.type} all={this.state.all} onClose={this.onCancel.bind(this)} />
				</CustomModal>
				<CustomModal
					visible={this.state.delVisible}
					width={388}

					title={"删除HR"}
					onCancel={this.onCancel.bind(this)}
				>
					<DelHr data={this.state.editData}  onOk={()=>{
						this.refresh()
						this.onCancel();
					}} />
				</CustomModal>
				{/* 拒绝弹窗 */}
				<Refuse
					ref={dom => this.refe = dom}
					type={this.state.type || ''}
					remarks={this.state.remarks || ''}
					onFinish={(req) => {
						if (this.state.type == 'readOnly') {
							this.refe.onCancel()
							return
						}
						this.refe.setState({
							// loading: true,
						}, () => {
							req.status = this.refe.state.status;
							req.c_uid = this.refe.state.id;
							global.Ajax.hhtc_request('company/companyUserEnable', req).then(res => {
								if (res.code == 1) {
									message.success(res.msg, 1.2)
									this.onOk()
								} else {
									message.error(res.msg, 1.2)
								}
								this.refe.setState({
									loading: false,
								}, () => {
									if (res.code == 1) {
										this.refe.onCancel()
									}
								})
							})
						})
					}}
					onCancel={() => {
						this.setState({
							type: '',
							remarks: '',
							visible: false,
							infoData: {}
						})
					}}
				/>
			</React.Fragment>
		)
	}
	onOk() {
		this.refresh()
		this.onCancel()
	}
	onCancel() {
		this.setState({
			visible: false,
			rechargeVisible: false,
			delVisible:false,
			editData: {},
			type: '',
			all: false,
		})
	}
}
