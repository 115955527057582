import { isFocusable } from '@testing-library/user-event/dist/utils';
import { Image } from 'antd';
import React from 'react';

export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            info: {}
        }
    }
    componentDidMount() {
        global.Ajax.hhtc_request('common/getCompanyInfo', { company_id: this.props.data.company_id }).then(res => {
            if (res.code == 1) {
                this.setState({
                    info: res.data,
                })
            }
        })
    }
    render() {
        const { info } = this.state;
        return (
            <div style={{ position: 'relative', paddingTop: 1 }}>
                <div className='wrapbox flexColumn'>
                    <div className='boxx'>
                        <div className='flexCenter' style={{ marginBottom: 15 }}>
                            <div className='avatar' style={{ width: 60, height: 60, }}>
                                <Image
                                    width={60}
                                    height={60}
                                    src={info.company_logo}
                                />
                            </div>
                            <div style={{ flex: 1, marginLeft: 10 }}>
                                <h1 className='name' style={{ fontSize: 24, }}>{info.company_name}</h1>
                                <p className='p1' style={{ fontSize: 12, }}>{info.company_type}·{info.scale}</p>
                            </div>
                        </div>
                        {/* <p className='t1'>企业相册</p>
                        <div className='imgbox borderbot1'>
                            <Image.PreviewGroup>
                                <Image
                                    width={70}
                                    height={70}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                                <Image
                                    width={70}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                                <Image
                                    width={70}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                                <Image
                                    width={70}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                                <Image
                                    width={70}
                                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                />
                            </Image.PreviewGroup>
                        </div> */}
                        <p className='t1'>企业地址</p>
                        <div className='borderbot1'>
                            <p className='p1 marginl'>{info.company_address}</p>
                        </div>
                        <p className='t1'>企业简介</p>
                        <div className='borderbot1'>
                            <p className='p1 marginl'>{info.company_introduce}</p>
                        </div>
                        {/* <p className='t1'>团队介绍</p>
                        <div className='borderbot1'>
                            <p className='p1 marginl'>两年律师事务所工作经验﹐四年大型集团企业法务工作经验﹔熟悉劳动仲裁、民事诉讼案件的处理﹔擅长企业合同审核与管理、知识产权申请与管理;规章制度制定及法制宣传工作﹔有较强的语言沟通能力和文字组织能力﹔有一定的英语表达能力。</p>
                        </div> */}
                        <p className='t1'>工商信息</p>
                        <div className='flex marginl' style={{ marginBottom: 6 }}>
                            <p className='p1' style={{ width: 100 }}>企业全称</p>
                            <p className='p5' style={{ flex: 1, textAlign: 'right' }}>{info.company_name}</p>
                        </div>
                        <div className='flexCenter marginl' style={{ marginBottom: 6 }}>
                            <p className='p1' style={{ width: 100 }}>法人代表</p>
                            <p className='p5' style={{ flex: 1, textAlign: 'right' }}>{info.legal}</p>
                        </div>
                        <div className='flexCenter marginl' style={{ marginBottom: 6 }}>
                            <p className='p1' style={{ width: 100 }}>注册资本</p>
                            <p className='p5' style={{ flex: 1, textAlign: 'right' }}>重庆市创客律师事务所</p>
                        </div>
                        <div className='flexCenter marginl' style={{ marginBottom: 6 }}>
                            <p className='p1' style={{ width: 100 }}>成立日期</p>
                            <p className='p5' style={{ flex: 1, textAlign: 'right' }}>重庆市创客律师事务所</p>
                        </div>
                        <div className='flexCenter marginl' style={{ marginBottom: 6 }}>
                            <p className='p1' style={{ width: 100 }}>统一信用代码</p>
                            <p className='p5' style={{ flex: 1, textAlign: 'right' }}>重庆市创客律师事务所</p>
                        </div>
                    </div>
                </div>
                <img alt='' src={require('../../imgs/shouj.png').default} className='boximg' />
            </div>
        )
    }
}
